import { get, post } from '../Config/config';

export const LeaveEligibilityApi = {
    get: (data) => get(`/employees/available-leaves?users_id=${data.users_id}`),
    add: (data) => post('/employees/available-leaves/store', data),
    update: (data) => post('/employees/available-leaves/update', data),
    getDetails: (data) => get('/employees/available-leaves/view?id=', { params: data }),
    delete: (data) => get('/employees/available-leaves/delete?id=', { params: data }),
    auto_update: (data) => get(`/employees/available-leaves/auto-update?users_id=${data.users_id}`),
}


