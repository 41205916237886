import React from 'react'
import ReactSelector from 'react-select';


function ReactSelectorComponent({ options, setValue, placeholder, name }) {
    return (
        <ReactSelector
            placeholder={placeholder}
            options={options}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            name={name}
            isClearable
            onChange={(selectedValue) => setValue(name, selectedValue?.value)}
        />
    )
}

export default ReactSelectorComponent