import React, { useEffect, useState } from 'react';
// import { useAppSettings } from "../../../hooks/use-app-settings";
import moment from "moment";
import { WaveformSpinner } from '../../../../../components/Loader';
import {
  Button,
  Card,
  Grid,
  Box,
  Typography
} from "@mui/material";
import { fetchEmployees } from '../../../../CommonFunction/Index';
import PageHeader from '../../../common/page-header'
import SearchField from '../../../../../components/searchEntry/Index'
import Table from '@mui/material/Table';
import { Employee } from '../../../../../api/Endpoints/Employee';
import { LeaveEligibilityApi } from '../../../../../api/Endpoints/LeaveEligibility';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import SelectX from '../../../../form/SelectX';
import { Fragment } from 'react';

const LeaveEligibility = () => {
  const { watch, formState: { errors }, control, setValue, reset } = useForm();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [TableUpdate, setTableUpdate] = useState(false)




  const fetchTable = (selectedEmployee) => {
    LeaveEligibilityApi.get({
      users_id: selectedEmployee
    }).then(response => {
      setList(response.data.data.data);
      setLoading(false);

    }).finally(() => {
      if (TableUpdate) {
        setTableUpdate(false)
      }

    });
  }





  useEffect(() => {
    if (watch('employee')) {
      UpdateRequest();
    } else {
      setList([])
    }
  }, [watch('employee')])

  const UpdateRequest = async () => {
    setLoading(true)
    let selectedEmployee = watch('employee')?.id
    await LeaveEligibilityApi.auto_update({ users_id: selectedEmployee })
    fetchTable(selectedEmployee)
  }

  return (
    <>

      {/* <PageHeader title={"Attendance report "} onCreateNew={handleCreateNew} /> */}

      <Card sx={{ p: 1, display: "flex", justifyContent: "space-between", mb: 1, mt: 1, boxShadow: 0 }}
        className="page_header">
        <PageHeader title={"Leave Eligibility"} />
      </Card >


      <Card sx={{ m: 2 }} variant="outlined">
        <Grid container justifyContent="end" mt={2} alignItems="center">
          <Grid item xs={4} mr={2}>
            <SelectX
              options={fetchEmployees}
              placeholder="Employee"
              control={control}
              name="employee"
            />
          </Grid>
        </Grid >

        {!TableUpdate && loading ? (
          <Fragment>
            <Box id="ui-ball-loader" >
              <WaveformSpinner />
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            {
              list.length > 0 ? (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Leave Type</TableCell>
                        <TableCell>Leave Count</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {list?.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>{row.leave_type} </TableCell>
                          <TableCell>{row.leave_count}</TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box id='data-not-found-msg'>
                  <Typography alignSelf={'center'}>Please select an employee</Typography>
                </Box>
              )
            }

          </Fragment>)
        }
      </Card >


    </>
  );
};

export default LeaveEligibility;
