import { IconButton, InputAdornment, TextField } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import React from 'react'

const SerachField = ({ handleSearchEntry, clearSerachInput, serachEntry, name }) => {
    return (
        <TextField
            variant="outlined"
            placeholder="Search"
            color="primary"
            name={name}
            onChange={handleSearchEntry}
            style={{ marginBottom: 4 }}
            value={serachEntry && serachEntry}
            InputProps={{
                style: {
                    borderRadius: 20,
                    marginLeft: 10,
                    backgroundColor: "rgb(248,250,251)",
                },
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton disabled>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment:
                    serachEntry && (
                        < InputAdornment position="end" >
                            <IconButton onClick={clearSerachInput}>
                                <HighlightOffIcon style={{ color: 'red' }} />
                            </IconButton >
                        </InputAdornment >
                    )


            }}
        />

    )
}

export default SerachField
