import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Popover, Button, TextField, Grid, InputAdornment } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import moment from 'moment';

const DateRangePickerComp = ({ selectedDateRange, onDateRangeChange }) => {


    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>

            <Grid container spacing={1} mt={1}>
                <Grid item xs={6} sm={6}>
                    <TextField
                        value={moment(selectedDateRange[0].startDate).format('DD-MM-YYYY')}
                        label="From"
                        disabled
                        id='date-range-input'
                        className="inputBox"
                        onClick={handleOpen}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EventIcon onClick={handleOpen} sx={{ cursor: 'pointer' }} />
                                </InputAdornment>
                            ),
                        }}

                    />


                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        value={moment(selectedDateRange[0].endDate).format('DD-MM-YYYY')}
                        readOnly
                        disabled
                        label="To"
                        id='date-range-input'
                        className="inputBox"
                        onClick={handleOpen}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EventIcon onClick={handleOpen} />
                                </InputAdornment>
                            ),
                        }}
                    />

                </Grid>
            </Grid>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ padding: 16 }}>
                    <DateRangePicker
                        onChange={(item) => {
                            onDateRangeChange([item.selection]);
                        }}
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        ranges={selectedDateRange}
                        months={2}
                        direction="horizontal"
                        className="calendarElement"
                    />
                </div>
            </Popover>
        </div >
    );
};

export default DateRangePickerComp;
