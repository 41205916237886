
import React, { createContext, useState, useContext } from 'react';


const StateContext = createContext();


export const useStateContext = () => useContext(StateContext);


export const StateProvider = ({ children }) => {
    const [userId, setUserId] = useState(null);


    const clearUserId = () => {
        setUserId(null);
    };

    return (
        <StateContext.Provider value={{ userId, setUserId, clearUserId, }}>
            {children}
        </StateContext.Provider >
    );
};
