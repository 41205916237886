import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Slide,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import SelectX from "../../../form/SelectX";
import TextInput from "../../../form/TextInput";
import DateInput from "../../../form/DateInput";
import { Departments } from "../../../../api/Endpoints/Departments";
import { Employee } from "../../../../api/Endpoints/Employee";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from "react";
import moment from "moment";
import ReactSelector from "react-select";
import "../../../../Custom.css";
import { DotSpinner } from "@uiball/loaders";
import {
  fetchDepartments,
  fetchEmployeeRoles,
  fetchEmployees,
} from "../../../CommonFunction/Index";
import { RatingAPI } from "../../../../api/Endpoints/Rating";
import { Delete, Edit } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
  name: yup.string().required("field is requried"),
  // phone_number: yup.string(),
  // employee_roles_id: yup.object().required("field is requried"),
  // departments_id: yup.object().required("field is requried"),
  // address: yup.string().required("field is requried"),
  // emergency_contacts: yup.string(),
  // joining_date: yup.date().required("field is requried"),
  // releving_date: yup.date().required("field is requried"),
  // personal_email: yup.string().required("field is requried"),
  // work_email: yup.string().required("field is requried"),
  // remarks: yup.string(),
});

const Create = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([])
  const [skills, setSkills] = useState([])
  const [selectedSkills, setSelectedSkills] = useState(null);

  const [formButtonStatus, setFormButtonStatus] = useState({
    label: "Submit",
    loading: false,
    disabled: false,
  });

  const handleClose = () => {
    setOpen(false);
    reset();
    setTimeout(() => {
      props.setEditId(undefined);
    }, 100);
  };

  const handleClickOpen = () => {
    props.onNew();
  };

  const Status = [
    { value: "Trainee", label: "Trainee" },
    { value: "Intern", label: "Intern" },
    { value: "Executive", label: "Executive" },
    { value: "Manager", label: "Manager" },
    { value: "Senior Executive", label: "Senior Executive" },
    { value: "Director", label: "Director" },
    { value: "Senior Manager", label: "Senior Manager" },
    { value: "General Manager", label: "General Manager" },
  ];
  const EmployeeTy = [
    { value: "Consultant", label: "Consultant" },
    { value: "Employee", label: "Employee" },
    { value: "Ex-Employee", label: "Ex-Employee" },
  ];
  const handleFormSubmit = (data) => {
    setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
    let JoinDate = moment(data.joining_date).format("YYYY-MM-DD");
    let DOB = moment(data.date_of_birth).format("YYYY-MM-DD");
    let official_date_of_birth = moment(data.official_date_of_birth).format(
      "YYYY-MM-DD"
    );
    let marriage_date = moment(data.marriage_date).format("YYYY-MM-DD");

    let dataToSubmit = {
      id: props?.editId,
      users_id: watch("user_id"),
      email: data.personal_email,
      personal_email: data.personal_email,
      // email: data.personal_email,
      personal_email: data.personal_email,
      username: data.name,
      name: data.name,
      address: data.address,
      phone_number: data.phone_number,
      employment_status: data.employment_status,
      official_date_of_birth: official_date_of_birth,
      marriage_date: marriage_date,
      releaving_date: data.releaving_date,
      employee_level: data.employee_level,
      phone_number: data.phone_number,
      departments_id: data.department?.id,
      managers_id: data.manager?.id,
      email: data.work_email,
      emergency_contacts: data.emergency_contacts,
      parant_organisations_id: 35, //Spiderworks India
      employee_roles_id: data.employee_roles_id?.id,
      address: data.address,
      joining_date: JoinDate,
      date_of_birth: DOB,
      remarks: data.remarks,
      facebook_url: data.facebook_url,
      linkedin_url: data.linkedin_url,
      instagram_url: data.instagram_url,
      blog_url: data.blog_url,
      reporting_email: data.reporting_email,
    };

    let action;
    if (data.work_email || data.personal_phone) {
      const ExtraUpdate = {
        users_id: watch("user_id"),
      };

      if (data.work_email) {
        ExtraUpdate.email = data.work_email;
        action = Employee.updateUserEmail(ExtraUpdate);
        ExtraUpdate.phone = data.personal_phone;
        action = Employee.updatePersonalPhone(ExtraUpdate);
      }
    }

    if (props.editId > 0) {
      action = Employee.update(dataToSubmit);
    } else {
      action = Employee.add(dataToSubmit);
    }

    action
      .then((response) => {
        if (response.data.errors) {
          toast.error("The email or phone number has already been taken");
          setFormButtonStatus({
            ...formButtonStatus,
            loading: false,
            disabled: false,
          });
          return;
        }

        toast.success(response.data.message, { autoClose: 3000 });
        props.onUpdate();
        setFormButtonStatus({
          ...formButtonStatus,
          loading: false,
          disabled: false,
        });
        handleClose();
      })
      .catch((errors) => {
        toast.error("Internal server error");
        setFormButtonStatus({
          ...formButtonStatus,
          loading: false,
          disabled: false,
        });
      });
  };
  useEffect(() => {
    if (props.editId > 0) {
      fetchDetails();
      setOpen(true);
    } else if (Number(props.editId) == 0) {
      setOpen(true);
    }
  }, [props.editId]);

  const fetchDetails = async () => {
    setLoading(true);
    let EmployeeDetail = await Employee.getEmployeeDetails({
      id: props.editId,
    });
    if (EmployeeDetail.data.status === "success") {
      let data = EmployeeDetail.data.data;
      let user = EmployeeDetail.data.data.user;
      setValue("name", data.user.name);
      setValue("work_email", data.user?.email);
      setValue("personal_email", data.email);
      setValue("user_id", data.users_id);
      setValue("department", data.department);
      setValue("employee_ID", data.user?.employee_ID);
      setValue("official_date_of_birth", data.official_date_of_birth);
      setValue("manager", user?.manager);
      setValue("marriage_date", data.marriage_date);
      setValue("date_of_birth", data.date_of_birth);
      setValue("releaving_date", data.releaving_date);
      setValue("phone_number", data.phone_number);
      setValue("personal_phone", data.user?.username);
      setValue("username", user?.username);
      setValue("employee_roles_id", data.role);
      setValue("address", data.address);
      setValue("emergency_contacts", data.emergency_contacts);
      setValue("joining_date", data.joining_date);
      setValue("remarks", data.remarks);
      setValue("employment_status", data.employment_status);
      setValue("date_of_birth", data.date_of_birth);
      setValue("employee_level", data.employee_level);
      setValue("releaving_date", data.releaving_date);
      setValue("facebook_url", data.user?.facebook_url);
      setValue("linkedin_url", data.user?.linkedin_url);
      setValue("instagram_url", data.user?.instagram_url);
      setValue("blog_url", data.user?.blog_url);
      setValue("reporting_email", data.user?.reporting_email);
    }

    setLoading(false);
  };

  // fetching employ rating parameter 
  const fetchRatings = () => {
    setLoading(true);
    RatingAPI.getRatingValues({}).then(response => {
        setList(response.data.data.data);
        setLoading(false);
    })
}
// console.log(list)
// console.log(selectedSkills)
useEffect(() => {
    fetchRatings()
},[])
const selectSkill = (data) => {
    setSelectedSkills(data?.name);
    setValue("list", data?.name || "");
  };

  const handleClick = () => {
    setSkills(skills.push(selectedSkills))
  }
  console.log(skills)

  return (
    <div>
      <Button
        className="create-btn"
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Add a margin to the icon if needed */}
        {props.changeText || (
          <>
            <AddIcon sx={{ marginRight: "2px" }} />
            Add
          </>
        )}
      </Button>

      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "43%",
            height: "100%",
            position: "fixed",
            right: 0,
            top: 0,
            bottom: 0,
            m: 0,
            p: 0,
            borderRadius: 0,
            maxHeight: "100%",
          },
        }}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle sx={{ pt: 1, pl: 1 }}>
          {parseInt(props.editId) > 0 ? "Edit Evaluation Template" : "Create Employe"}
        </DialogTitle>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "30vh",
            }}
          >
            <DotSpinner size={47} speed={0.9} color="rgb(63,197,149" />
          </Box>
        ) : (
          <>
            <DialogContent DialogContent sx={{ p: 2 }}>
              <Grid sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <Grid >
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Grid container>
                    <Grid sx={{ p: 1 }} item xs={12}>
                          <TextInput
                            control={control}
                            name="name"
                            label="Title"
                            value={watch("name")}
                          />
                        </Grid>
                        <Grid sx={{ p: 1, mt: 3 }} item xs={12}>
                        <Typography
            sx={{ color: "black", fontWeight: 700, cursor: "pointer" }}
          >Rating parameters:</Typography>
          </Grid>
          <Grid sx={{ p: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }} item xs={12}>
            <Typography
            sx={{ color: "gray", fontWeight: 500, cursor: "pointer" }}
          >sample data</Typography>
          <Grid sx={{display: "flex", alignItems: "center", gap: 2}}>
          <Edit/>
            <Delete/> 
            </Grid>
            
          </Grid>
          <Grid sx={{ p: 1, display: "flex", gap: 3 }} item xs={12}>
            <Grid sx={{ width: "50%" }}>
          <ReactSelector
          placeholder= "Rating parameter"
          onInputChange={fetchRatings}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 })}}
          options= {list}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          value={list.find(
            (options) => options.name === watch("list")
          )}
          isClearable
          name="list"
          defaultValue={watch("list")}
          onChange={(selectedOption) => selectSkill(selectedOption)}
          />
          </Grid>
          <Grid sx={{ width: "30%" }}>
          <Button
          onClick={handleClick}
          variant="outlined" 
          sx={{
            borderColor: 'rgb(0,158,102)',
            color: 'rgb(0,158,102)'
          }}
          >Add</Button>
          </Grid>
          </Grid>
                      </Grid>
                     
                  </form>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default Create;
