import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Typography,
} from '@mui/material';
import Rating from '@mui/material/Rating';
import { RatingAPI } from '../../../../../api/Endpoints/Rating';
import SelectX from '../../../../form/SelectX';
import { useForm } from 'react-hook-form';
import { useStateContext } from '../../../../../contexts/SignIn/UsersID/usersID';

function Ratings({ ID }) {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({});

    const [editIndex, setEditIndex] = useState(-1);
    const [showAddRating, setShowAddRating] = useState(false);
    const [newItemName, setNewItemName] = useState('');
    const [newRating, setNewRating] = useState(0);
    const [editName, setEditName] = useState('');
    const { userId } = useStateContext();
    const [editRating, setEditRating] = useState(0);
    const [data, setData] = useState([]);




    const handleEdit = (index, name, rating) => {
        setEditIndex(index);
        setEditName(name);
        setEditRating(rating);
    };

    const handleSaveEdit = (index) => {
        const newData = [...data];
        newData[index] = { name: editName, rating: editRating };
        setData(newData);
        setEditIndex(-1);
    };

    const handleCancelEdit = () => {
        setEditIndex(-1);
    };

    const handleAddRating = () => {
        setShowAddRating(true);
    };

    const handleSaveNewRating = () => {
        if (newItemName && newRating >= 0) {
            setData([...data, { name: newItemName, rating: newRating }]);
            setNewItemName('');
            setNewRating(0);
            setShowAddRating(false);
        }
    };

    const handleCloseAddRating = () => {
        setShowAddRating(false);
        setNewItemName('');
        setNewRating(0);
    };

    let fetchRatingValues = async (e) => {
        const response = await RatingAPI.getRatingValues({ keyword: e });
        if (typeof response.data.data.data !== "undefined") {
            return response.data.data.data;
        } else {
            return [];
        }

    }

    useEffect(() => {
        fetchRatings()
    }, [])

    const fetchRatings = async () => {
        const response = await RatingAPI.get({ users_id: userId })

        setData([response.data.data])
    }




    const handleFormSubmit = async (data) => {


        const dataToSubmit = {
            users_id: userId,
            values: [
                {
                    parameter_id: data.parameter_id.id,
                    value: newRating
                }

            ]
        }


        let action;
        action = await RatingAPI.add(dataToSubmit);

    }

    const [selectedValues, setSelectedValues] = useState([]);

    const handleSelectChange = (index, selectedOption) => {
        // Create a copy of the array and update the selected value for the specific index
        const updatedSelectedValues = [...selectedValues];
        updatedSelectedValues[index] = selectedOption;
        setSelectedValues(updatedSelectedValues);
    };
    return (
        <div>
            <Button onClick={handleAddRating} variant="contained" color="primary">
                Add Rating
            </Button>

            <Dialog open={showAddRating} onClose={handleCloseAddRating} fullWidth>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <DialogTitle textAlign={'center'} mb={2}>Add Rating</DialogTitle>
                    <DialogContent
                    >
                        <Grid item xs={6} sm={6}>
                            <SelectX
                                defaultOptions
                                label={"Choose Skill Assessment"}
                                options={fetchRatingValues}
                                control={control}
                                error={errors.message}
                                fullwidth
                                name={'parameter_id'}
                                defaultValue={watch('parameter_id')}
                            />
                        </Grid>
                        <Grid container item xs={12} sm={12} alignItems="center" mt={3}>
                            <Typography variant="body1">
                                {'Rating  : '}
                            </Typography>
                            <Rating
                                name="new-rating"
                                value={newRating}
                                precision={0.5}
                                onChange={(event, newValue) => setNewRating(newValue)}
                            />
                            {!newRating == 0 &&
                                <Typography variant="body1">
                                    {' / '}
                                    <span style={{ fontWeight: 'bold' }}>{newRating}</span>
                                </Typography>}

                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAddRating} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleSaveNewRating} variant="contained" color="primary" type='submit'>
                            Save
                        </Button>

                    </DialogActions>
                </form>
            </Dialog>

            <TableContainer component={Paper}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item</TableCell>
                                <TableCell>Rating</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((item, index) => (
                                item.rating_values.map((ratingValue, subIndex) => (

                                    < TableRow key={`${index}`}>
                                        <TableCell>
                                            {editIndex === index ? (
                                                <SelectX
                                                    defaultOptions
                                                    options={fetchRatingValues}
                                                    control={control}
                                                    error={errors.message}
                                                    fullwidth
                                                    name={`parameter_id_${subIndex}`} // Make sure to use a unique name for each row
                                                    defaultValue={ratingValue?.parameter} // Use the correct path

                                                />

                                            ) : (

                                                ratingValue?.parameter?.name

                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {editIndex === index ? (
                                                <Rating
                                                    name={`edit-rating-${index}`}
                                                    value={editRating}
                                                    precision={0.5}
                                                    onChange={(event, newValue) => setEditRating(newValue)}
                                                />
                                            ) : (
                                                <Rating
                                                    name={`rating-${index}`}
                                                    value={item.rating}
                                                    precision={0.5}
                                                    readOnly
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {editIndex === index ? (
                                                <>
                                                    <Button onClick={() => handleSaveEdit(index)} variant="contained" color="primary">
                                                        Save
                                                    </Button>
                                                    <Button onClick={handleCancelEdit} variant="contained" color="secondary">
                                                        Cancel
                                                    </Button>
                                                </>
                                            ) : (
                                                <Button onClick={() => handleEdit(index, item.name, item.rating)} variant="contained" color="primary">
                                                    Edit
                                                </Button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </TableContainer>
        </div >
    );
}

export default Ratings;