import React from 'react';
import { DotSpinner, Waveform } from '@uiball/loaders';

const customStyle = {
    size: 47,
    speed: 0.9,
    color: 'rgb(63, 197, 149)',
};

const waveformstyle = {
    sizeP: 50,
    lineWeight: 3.5,
    speed: 1,
    color: "rgb(63,197,149"
};

const Spinner = () => {
    return <DotSpinner {...customStyle} />;
};
export const WaveformSpinner = () => {
    return <Waveform {...waveformstyle} />;
};

export default Spinner  
