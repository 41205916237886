import React, { useState } from 'react';
// import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, Typography } from "@mui/material";
import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, Slide, Typography } from "@mui/material";
import ReactSelector from 'react-select';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import TextInput from '../../../form/TextInput'
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { ErrorMessage } from "@hookform/error-message";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from 'react';
import { Holidaycalerndar } from '../../../../api/Endpoints/Holidaycalerndar';
import { EmployeeRole } from '../../../../api/Endpoints/EmployeeRoles';




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const scheme = yup.object().shape({
    role: yup.string().required(),
});


const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)


    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            props.setEditId(undefined)
        }, 100)
    };


    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });





    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true });

        let dataToSubmit = {
            id: props?.editId,
            name:data.role




        };

        console.log(dataToSubmit)

        let action;
        if (props.editId > 0) {

            console.log("this datatosubmit", dataToSubmit);
            action = EmployeeRole.update(dataToSubmit);

        } else {
            console.log("this datatosubmit", dataToSubmit);
            action = EmployeeRole.add(dataToSubmit);

        }

        action.then(response => {
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            if (response.data.errors) {
                let err = response.data.errors
                Object.keys(err).forEach((key) => {
                    toast.error(err[key].name);
                });

                return
            }

            toast.success(response.data.message)

            props.onUpdate();
            setOpen(false)
        }).catch(errors => {
            toast.error("Internal server error");
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            setOpen(false)
        })
    }



    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchDetails()
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);
        }

    }, [props.editId])

    // const fetchDetails =()=>{
    //     Documentcategory.getDetails({ id: props.editId }).then((response)=>{
    //         console.log(response);
    //         if(response.data.status === "success"){
    //             let data = response.data.data
    //             setValue('name', data.name);
    //             setValue('remarks', data.remarks);
    //         }
    //     }).catch(errors=>{
    //         console.log(errors);
    //     })
    // }


    const fetchDetails = async () => {
        setLoading(true)
        let details = await EmployeeRole.getDetails({ id: props.editId });
        if (details.data.status === "success") {
            let data = details.data.data;
            console.log("thsiis sieeeee datas", data);
            setValue('role', data.name);
        }
        setLoading(false)

    }







    return (
        <div>
            <Button
                className="create-btn"
                variant="contained"
                onClick={handleClickOpen}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <AddIcon sx={{ marginRight: "2px" }} />
                Add Employee Role
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "40%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Role " : "Create Role"}</DialogTitle>


                {loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <CircularProgress />
                    </Box >
                ) : (
                    <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <DialogContent>
                                <Grid mt={3} >

                                    <Grid item xs={12} sm={12}>

                                        <Grid container spacing={1}>

                                            <Grid sx={{ p: 1 }} sm={12}>
                                                <TextInput control={control} name="role"
                                                    label="Role"
                                                    value={watch('role')} />
                                            </Grid>


                                        </Grid>


                                        <Grid item xs={12} mt={2}>
                                            <ErrorMessage
                                                errors={errors}
                                                name="multipleErrorInput"
                                                render={({ messages }) =>
                                                    messages &&
                                                    Object.entries(messages).map(([type, message]) => (
                                                        <p key={type}>{message}</p>
                                                    ))
                                                }
                                            />


                                        </Grid>
                                    </Grid>

                                </Grid>





                            </DialogContent>

                            <DialogActions>
                                <Button onClick={handleClose} variant='outlined' color='error'>Close</Button>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" color='success' type="submit">{formButtonStatus.label}</LoadingButton>

                            </DialogActions>
                        </form>
                    </>
                )
                }
            </Dialog >
        </div >

    );
};

export default Create;