import { get, post } from './../Config/config';

export const RatingAPI = {

    get: (data) => get('/employees/ratings/view?', { params: data }),
    add: (data) => post('/employees/ratings/store', data),

    addFamilyPhoto: (data) => post('/employees/update-photo', data),


    //Rating Values

    getRatingValues: (data) => get('/employees/ratings/parameters', { params: data }),
    addRatingValues: (data) => post('/employees/ratings/parameters/store', data),
    updateRatingValues: (data) => post('/employees/ratings/parameters/update', data),
    getRatingValuesDetails: (data) => get('/employees/ratings/parameters/view?id=', { params: data }),


}
