import { Backdrop, Button, TextField, Grid, Card, CircularProgress, Typography, InputAdornment, IconButton } from "@mui/material";
import PageHeader from "../../common/page-header";
import Create from "./create";
import { useState } from "react";
import { Employee } from "../../../../api/Endpoints/Employee";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Delete from "../../../../components/Popups/Delete";
// import View from "./View";
import { Departments } from "../../../../api/Endpoints/Departments";
import { Documentcategory } from "../../../../api/Endpoints/Documentcategory";
import { Holidaycalerndar } from "../../../../api/Endpoints/Holidaycalerndar";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import { EmployeeRole } from "../../../../api/Endpoints/EmployeeRoles";



const EmployeeRoles = () => {

    const columns = [

        {
            field: 'role', headerName: 'Role',sortable: true, width: 600, renderCell: (params) => (

                <Typography sx={{ color: 'rgb(0,158,102)', fontWeight: 500 }} onClick={() => { ViewTabs(params.row.id) }}>{params.row.name}</Typography>

            )
        },


        {
            field: 'edit',
            headerName: 'Edit',
            width: 110,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => (
                <Button size='small' onClick={() => handleEdit(params.row.id)}>
                    <EditIcon style={{ color: 'gray', height: 19 }} />
                </Button>
            ),
        },

        {
            field: 'delete',
            headerName: 'Delete',
            width: 90,
            renderCell: (params) => (

                <Button size='small' onClick={() => handleDelete(params.row.id)}>
                    <DeleteIcon style={{ color: 'red', height: 19 }} />
                </Button>

            ),
        },



    ]

    const [refresh, setRefresh] = useState(false)
    const [editId, setEditId] = useState()
    const [showDetails, setDetails] = useState(false)
    const [pageNumber, setPageNumber] = useState(0);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ID, setID] = useState(0)
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [searchKeyword, setSearchKeyword] = useState();

    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }
    const handleEdit = (id) => {
        setRefresh(Math.random);
        setEditId(parseInt(id));
    }

    const ViewTabs = (id) => {
        setID(id)
        setDetails(true)
    }


    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    const handleDelete = (id) => {

        setID(id)
        setDeletePopup(true)
    }

    useEffect(() => {
        fetchTable();
    }, [, searchKeyword, pageNumber])



    const fetchTable = () => {
        setLoading(true);
        EmployeeRole.get({ keyword: searchKeyword, page: Number(pageNumber) + 1,limit:50  }).then(response => {
            console.log(response.data.data);
            setList(response.data.data);
            setLoading(false);
        })
    }
    console.log(list)

    const row = [
        {
            id: 1,
            subject: "All Employee Meeting",
            time: '11:00 AM'
        }
    ]
    return (

        <>
            <Card sx={{ p: 1, display: "flex", justifyContent: "space-between", mb: 1, mt: 1, boxShadow: 0 }}
                className="page_header" >
                <PageHeader title={"Employee Roles"} />
                <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />

            </Card >

            {showDeletePopup && <Delete
                ID={ID}
                setID={setID}
                setDeletePopup={setDeletePopup}
                showDeletePopup={showDeletePopup}
                Callfunc={() => fetchTable()}
                url="/employees/roles/delete?id="
                title="Employee Role" />}


            {/* {showDetails && <View ID={ID} setID={setID} setDetails={setDetails} />} */}


            <Card sx={{ m: 2 }} variant="outlined">



                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid width={'30%'} pt={1}>
                    <TextField
                        variant="outlined"
                        placeholder="Search"
                        color="primary"
                        onChange={handleSearchEntry}
                        style={{ marginBottom: 4 }}
                        InputProps={{
                            style: {
                                borderRadius: 20,
                                marginLeft: 10,
                                backgroundColor: "rgb(248,250,251)",
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton disabled>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>


                {typeof list.data === "object" &&
                    <DataGrid
                        paginationMode="server"
                        rows={list.data}
                        columns={columns}
                        page={pageNumber}
                        pageSize={50}
                        rowHeight={20}
                        rowsPerPageOptions={[50]}
                        rowCount={list.total}
                        onPageChange={handlePageChange}
                        autoHeight
                        density={"comfortable"}
                        getRowHeight={() => 35}

                    />
                }
            </Card>
        </>
        //     )
        // }
        //         </>

    )
};

export default EmployeeRoles;