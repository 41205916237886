import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from '@mui/material/Stack';
import { Avatar, Menu, MenuItem } from '@mui/material';
import { useAuth } from '../hooks/use-auth';

export default function ButtonAppBar() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const auth = useAuth();
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };



    const logoutHandler = () => {
        auth.logout();
    }


    let UserName = localStorage.getItem('UserName');
    let UserEmail = localStorage.getItem('UserEmail');

    return (
        <Box sx={{ flexGrow: 1, mb: 10 }}>
            <AppBar position="fixed" color="inherit">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
                    <Stack direction="row" spacing={1} alignItems="center" mr={1}>
                        <Avatar
                            src={'https://www.spiderworks.in/theme/web/img/logo-sw.png'}
                            sx={{ objectFit: 'cover', width: 36, height: 36 }}
                            onClick={handleMenuOpen} // Open the menu when the avatar is clicked
                        />

                        <div onClick={handleMenuOpen} style={{ cursor: 'pointer' }}>
                            <Typography color="inherit" style={{ marginTop: 5, fontSize: '14px' }}>
                                {UserName}
                            </Typography>
                            <Typography
                                color="inherit"
                                style={{ fontSize: '12px', color: 'rgb(126,132,159)', cursor: 'pointer' }}

                            >
                                {UserEmail}
                            </Typography>

                        </div>

                        {/* Menu */}
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}

                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            getContentAnchorEl={null}
                        >


                            <MenuItem onClick={logoutHandler}
                                sx={{ width: 150, height: 50 }}>
                                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.81837 0.592529H8.45512C9.20259 0.592529 9.91946 0.853249 10.448 1.31733C10.9766 1.78142 11.2735 2.41085 11.2735 3.06716V6.36667H10.334V3.06716C10.334 2.62962 10.1361 2.21 9.78371 1.90061C9.43134 1.59122 8.95343 1.41741 8.45512 1.41741H2.81837C2.32005 1.41741 1.84214 1.59122 1.48978 1.90061C1.13741 2.21 0.939457 2.62962 0.939457 3.06716V13.7906C0.939457 14.2281 1.13741 14.6477 1.48978 14.9571C1.84214 15.2665 2.32005 15.4403 2.81837 15.4403H8.45512C8.95343 15.4403 9.43134 15.2665 9.78371 14.9571C10.1361 14.6477 10.334 14.2281 10.334 13.7906V10.4911H11.2735V13.7906C11.2735 14.4469 10.9766 15.0763 10.448 15.5404C9.91946 16.0045 9.20259 16.2652 8.45512 16.2652H2.81837C2.07089 16.2652 1.35403 16.0045 0.825482 15.5404C0.296935 15.0763 0 14.4469 0 13.7906V3.06716C0 2.41085 0.296935 1.78142 0.825482 1.31733C1.35403 0.853249 2.07089 0.592529 2.81837 0.592529ZM5.63674 8.01642H16.2056L13.1524 5.33557L13.7724 4.71692L18 8.42886L13.7724 12.1408L13.1524 11.5222L16.2056 8.8413H5.63674V8.01642Z" fill="#3C3C3C" />
                                </svg> <Typography sx={{ fontWeight: 'semi-bold', pl: 1 }}>Log Out</Typography> </MenuItem>
                        </Menu>
                    </Stack>

                </Toolbar>
            </AppBar>
        </Box>
    );
}
