import { Backdrop, Button, TextField, Grid, Card, CircularProgress, Typography, InputAdornment, IconButton, Box } from "@mui/material";
import PageHeader from "../../common/page-header";
import Create from "./create";
import { useState } from "react";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Delete from "../../../../components/Popups/Delete";
import View from "./View";
import { Holidaycalerndar } from "../../../../api/Endpoints/Holidaycalerndar";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";
import { Fragment } from "react";
import { Waveform } from "@uiball/loaders";
import { useForm } from "react-hook-form";
import ReactSelectorComponent from "../../common/ReactSelector";



const HolidayCalendar = () => {

    const columns = [

        {
            field: 'reason', headerName: 'Reason', width: 400, renderCell: (params) => (

                <Typography sx={{ color: 'rgb(0,158,102)', fontWeight: 500 }} onClick={() => { ViewTabs(params.row.id) }}>{params.value}</Typography>

            )
        },
        { field: 'holiday', headerName: 'Holiday Date', width: 400, renderCell: (params) => <>{moment(params.value).format('DD-MM-YYYY')}</> },



        {
            field: 'edit',
            headerName: 'Edit',
            width: 110,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => (
                <Button size='small' onClick={() => handleEdit(params.row.id)}>
                    <EditIcon style={{ color: 'gray', height: 19 }} />
                </Button>
            ),
        },

        {
            field: 'delete',
            headerName: 'Delete',
            width: 90,
            renderCell: (params) => (

                <Button size='small' onClick={() => handleDelete(params.row.id)}>
                    <DeleteIcon style={{ color: 'red', height: 19 }} />
                </Button>

            ),
        },



    ]

    const [refresh, setRefresh] = useState(false)
    const [editId, setEditId] = useState()
    const [showDetails, setDetails] = useState(false)
    const [pageNumber, setPageNumber] = useState(0);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ID, setID] = useState(0)
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [searchKeyword, setSearchKeyword] = useState();
    const [TableUpdate, setTableUpdate] = useState(false)
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({});

    const ForTableUpdate = () => {
        setTableUpdate(true)
        fetchTable()
    }
    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }
    const handleEdit = (id) => {
        setRefresh(Math.random);
        setEditId(parseInt(id));
    }

    const ViewTabs = (id) => {
        setID(id)
        setDetails(true)
    }


    const handlePageChange = (page) => {
        setPageNumber(page)
    }


    const handleDelete = (id) => {
        setID(id)
        setDeletePopup(true)

    }


    useEffect(() => {
        fetchTable();
    }, [searchKeyword, pageNumber, watch('Month'), watch("Year")])

    // Year Calculator function


    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const yearOptions = [];
        //Previouse 10 year
        for (let i = currentYear - 10; i <= currentYear; i++) {
            yearOptions.push({ value: i, label: i.toString() });
        }
        //Next 10 year
        for (let i = currentYear + 1; i <= currentYear + 10; i++) {
            yearOptions.push({ value: i, label: i.toString() });
        }
        return yearOptions;
    };
    const yearOptions = generateYearOptions();

    const fetchTable = () => {
        setLoading(true);
        Holidaycalerndar.get({
            keyword: searchKeyword,
            page: Number(pageNumber) + 1,
            month: watch('Month'),
            year: watch('Year'),
            limit: 20
        }).then((res) => {
            setList(res.data.data)
            setLoading(false)
        }).catch(err => {
            setLoading()
        })
    }


    const months = [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' },
    ];

    return (

        <Fragment>
            <Card sx={{ p: 1, display: "flex", justifyContent: "space-between", mb: 1, mt: 1, boxShadow: 0 }}
                className="page_header" >
                <PageHeader title={"Holiday Calendar"} total={list && !loading && list.total} />
                <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />

            </Card >

            {showDeletePopup && <Delete
                ID={ID}
                setID={setID}
                setDeletePopup={setDeletePopup}
                showDeletePopup={showDeletePopup}
                Callfunc={() => fetchTable()}
                url="/hr/holiday-calendar/delete?id="
                title="Holiday" />}


            {showDetails && <View ID={ID} setID={setID} setDetails={setDetails} />}


            <Card sx={{ m: 2 }} variant="outlined">
                <Grid display={"flex"} justifyContent={'end'} pt={1} xs={12} sm={12} >
                    <Grid display={'flex'} mr={3}>
                        <Grid mr={1} sx={{ width: 200 }} >
                            <ReactSelectorComponent
                                setValue={setValue}
                                name={'Month'}
                                placeholder={"Month"}
                                options={months}
                            />
                        </Grid>
                        <Grid sx={{ width: 200 }}>
                            <ReactSelectorComponent
                                setValue={setValue}
                                name={'Year'}
                                placeholder={"Year"}
                                options={yearOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {!TableUpdate && loading ? (
                    <Fragment>
                        <Box id="ui-ball-loader" >
                            <Waveform
                                size={50}
                                lineWeight={3.5}
                                speed={1}
                                color="rgb(63,197,149"
                            />
                        </Box>
                    </Fragment>
                ) : (
                    <Fragment>
                        {list?.data?.length > 0 ? (
                            <Fragment>

                                {
                                    typeof list.data === "object" &&
                                    <DataGrid
                                        paginationMode="server"
                                        rows={list?.data}
                                        columns={columns}
                                        page={pageNumber}
                                        pageSize={20}
                                        rowHeight={20}
                                        rowsPerPageOptions={[10]}
                                        rowCount={list.total}
                                        onPageChange={handlePageChange}
                                        autoHeight
                                        density={"comfortable"}
                                        getRowHeight={() => 35}

                                    />
                                }
                            </Fragment>
                        ) : (
                            <Box id='data-not-found-msg'>
                                <Typography alignSelf={'center'}>No holidays found for this date range.</Typography>
                            </Box>
                        )}

                    </Fragment>


                )
                }

            </Card >
        </Fragment>


    )
};

export default HolidayCalendar;