import SelectX from "../../project-portal/form/SelectX";
import { fetchEmployees } from "../../project-portal/CommonFunction/Index";
import ReactSelector from 'react-select';
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Menu, MenuItem, Modal, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Document } from "../../api/Endpoints/Document";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

function EmployeesListPoup({ setOpen, id, setSelectedValue, refresh, ExistedUsers }) {

    const scheme = yup.object().shape({
        employees: yup.mixed().required("Employee is required").typeError("Employee is required"),

    })

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, clearErrors, reset } = useForm({ resolver: yupResolver(scheme) });

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });


    const handleClose = () => {
        setOpen(false)
        setSelectedValue && setSelectedValue("Public")
    };



    const onSubmit = async (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        const selectedEmployees = data.employees;
        const dataToSubmit = {
            id: id,
            permission: "Private",
            users: ExistedUsers ? [...ExistedUsers, ...selectedEmployees.map(item => item.id)] : selectedEmployees.map(item => item.id),
        };

        const response = await Document.documentPermssion(dataToSubmit)

        if (response.status) {
            toast.success('Document permissions has been successfully saved.', { autoClose: 1000 })
            setOpen(false)
            refresh(Math.random)
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        } else {
            toast.error('Failed to save document permissions. Please try again later.', { autoClose: 5000 })
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        }


    }
    return (
        <Dialog open={true} >
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle textAlign={'center'}>Select Employee</DialogTitle>

                <DialogContent sx={{ width: '500px', m: 2 }}>
                    <Grid item xs={12} sm={12}  >
                        <SelectX
                            ismultiple
                            control={control}
                            name="employees"
                            options={fetchEmployees}
                            defaultValue={watch('employees')}
                            error2={errors?.employees?.message}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="error" variant="outlined" onClick={handleClose} >
                        Close
                    </Button>
                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                </DialogActions>
            </form>
        </Dialog >
    )
}


export default EmployeesListPoup;



