import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Card, Box, CircularProgress, Typography, IconButton, } from '@mui/material';
import { Selfi } from '../../../../../api/Endpoints/Selfie';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import AddSelfiePopup from '../../../../../components/Popups/selfiePopup';
import Delete from '../../../../../components/Popups/Delete';
import ImageView from '../../../../../components/Popups/ImageView';
import { useStateContext } from '../../../../../contexts/SignIn/UsersID/usersID';
import { Employee } from '../../../../../api/Endpoints/Employee';
import { faHourglass2 } from '@fortawesome/free-solid-svg-icons';


function Selfies({ employeeID }) {
    const [showImageUploadPopup, setShowImagePopup] = useState(false)

    const [loading, setLoading] = useState(false);
    const [ID, setID] = useState(0)
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [ZoomView, setZoomView] = useState(false)
    const [photoType, setPhotoType] = useState('')
    const [DeleteType, setDeleteType] = useState('')
    const [photos, setPhotos] = useState([]);
    const [employeeDetails, setEmployeeDetails] = useState()
    const { userId } = useStateContext();

    useEffect(() => {
        fetchSelfies()
    }, []);


    const fetchSelfies = () => {
        setPhotos([]);
        setLoading(true);
        Employee.getEmployeeDetails({ id: employeeID })
            .then(response => {
                console.log(response.data.data);
                setEmployeeDetails(response.data.data)

                const photosArray = [];

                if (response.data.data.family_photo) {
                    photosArray.push({ type: 'family_photo', url: response.data.data.family_photo });
                }
                if (response.data.data.selfi_photo) {
                    photosArray.push({ type: 'selfi_photo', url: response.data.data.selfi_photo });
                }

                setPhotos(photosArray);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    };
    const handleShowpopup = (photoType) => {
        if (photoType === 'family') {
            setPhotoType('family')
        } else {
            setPhotoType('')
        }
        setShowImagePopup(true)
        setID(employeeID)
    }

    const handleEditButtonClick = (id) => {
        setID(id)
        setShowImagePopup(true)
    }
    const handleDelete = (data) => {
        console.log(data);
        setDeleteType(data.type)
        setID(employeeID)
        setDeletePopup(true)
    }

    const handleZoomView = (photo) => {

        setDeleteType(photo.url)
        setZoomView(true)
    }

    return (
        <div style={{ marginTop: 1 }}>
            {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                    <CircularProgress className='ld-circle' size={33} />
                    <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                        Loading...
                    </Typography>
                </Box>
            ) : (
                <>
                    {showDeletePopup && <Delete
                        ID={ID}
                        setID={setID}
                        setDeletePopup={setDeletePopup}
                        Callfunc={() => fetchSelfies()}
                        // url="/employees/remove-photo?id="
                        title="Photo"
                        type={DeleteType}
                    />
                    }




                    <div className='flex' style={{ width: '100', display: 'flex', justifyContent: "end", marginRight: 10 }}>
                        {!photos.find(photo => photo.type === 'selfi_photo') && (
                            <Button
                                className="create-btn"
                                variant="contained"
                                component="label"
                                onClick={handleShowpopup}
                            >
                                Add Selfie
                            </Button>
                        )}

                        {!photos.find(photo => photo.type === 'family_photo') && (
                            <Button
                                id="familyPhotoButton"
                                className="create-btn"
                                variant="contained"
                                component="label"
                                onClick={() => handleShowpopup('family')}
                            >
                                Add Family Photo
                            </Button>
                        )}
                    </div>


                    {
                        showImageUploadPopup &&
                        <AddSelfiePopup
                            showImageUploadPopup={showImageUploadPopup}
                            setShowImagePopup={setShowImagePopup}
                            callFunc={() => fetchSelfies()}
                            ID={ID}
                            setID={setID}
                            photoType={photoType && photoType}
                        />
                    }


                    {photos.length === 0 ? (
                        <Box display={'flex'} justifyContent={'center'}>
                            <p>No Photo Added</p>
                        </Box>
                    ) : (
                        <>
                            {photos.map(photo => {
                                console.log("this sifsdfsd", photo.url);
                                return (
                                    <Card key={photo.type} sx={{ mt: 5 }}>
                                        <div onClick={() => handleZoomView(photo)}>
                                            <img
                                                src={`https://dashboard.omnisellcrm.com/${photo.url}`}
                                                style={{ maxWidth: '20%', height: 'auto' }}
                                            />
                                        </div>
                                        {ZoomView && <ImageView imageUrl={DeleteType} fileName={''} setZoomView={setZoomView} />}
                                        {/* <DocumentView documentURL={list.photo} fileName={list.title} /> */}
                                        <Box width={'20%'} display={'flex'} justifyContent={'end'} mt={1}>
                                            <IconButton onClick={() => handleDelete(photo)}><DeleteIcon style={{ color: 'red', height: 19 }} /></IconButton>
                                        </Box>
                                    </Card>
                                );
                            })}
                        </>
                    )}



                </>
            )
            }
        </div >
    );
}

export default Selfies;
