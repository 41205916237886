import React, { useState } from 'react';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

const EditPopup = ({ note, showEditpopup, setEditpopup, editNote, onSave, formButtonStatus }) => {


    const handleClose = () => {
        editNote(null)
        setEditpopup(false)
    }
    return (
        <Dialog open={showEditpopup} fullWidth>
            <DialogTitle sx={{ textAlign: 'center' }}>Edit Note</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    autoFocus
                    multiline
                    rows={5}
                    value={note.note}
                    onChange={(e) => editNote({
                        ID: note.ID,
                        note: e.target.value
                    })}
                />
            </DialogContent>
            <DialogActions>
                <Button color="error" variant='outlined' onClick={handleClose}>
                    Cancel
                </Button>
                <LoadingButton
                    sx={{ ml: 2 }}
                    onClick={onSave}
                    loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                    type="submit" variant='outlined' color='success'>{formButtonStatus.label}</LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default EditPopup;
