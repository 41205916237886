import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PageHeader from '../../../common/page-header';
import moment from "moment";
import "../../../../../Custom.css"
import { EmergencyContactsAPI } from '../../../../../api/Endpoints/EmergencyContacts';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
    Backdrop,
    Button,
    Card,
    CircularProgress,
    Divider,
    List,
    Box,
    Modal,
    IconButton,
    ListItemIcon,
    ListItem,
    ListItemText,
    TextField,
    Typography,
    Grid
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import { useStateContext } from '../../../../../contexts/SignIn/UsersID/usersID';
import TextInput from '../../../../form/TextInput';
import Delete from '../../../../../components/Popups/Delete';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '80vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};




function EmergencyContact() {

    const columns = [

        {
            field: 'contact_name', headerName: 'Contact Name', width: 280, renderCell: (params) => (

                <Typography sx={{ color: 'rgb(0,158,102)', fontWeight: 300 }} >{params.value}</Typography>

            )
        },
        { field: 'phone_number', headerName: 'Phone Number', width: 200, renderCell: (params) => <>{params.value}</> },
        {
            field: 'relationship', headerName: 'Relationship', width: 300, renderCell: (params) => <>{params.value}</>,

        },

        {
            field: 'edit',
            headerName: 'Edit',
            width: 80,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => (
                <Button size='small' onClick={() => handleEdit(params.row.id)} >
                    <EditIcon style={{ color: 'gray', height: 19 }} />
                </Button >
            ),
        },

        {
            field: 'delete',
            headerName: 'Delete',
            width: 90,
            renderCell: (params) => (

                <Button size='small' onClick={() => handleDelete(params.row.id)} >
                    <DeleteIcon style={{ color: 'red', height: 19 }} />
                </Button >

            ),
        },



    ]

    const { handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({});



    const [open, setOpen] = useState(false);
    const [list, setList] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [loading, setLoading] = useState(false);
    const [ID, setID] = useState(0)
    const { userId } = useStateContext();
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });



    useEffect(() => {
        fetchEmergencyContacts()
    }, [])
    const fetchEmergencyContacts = async () => {
        setLoading(true);

        try {
            const response = await EmergencyContactsAPI.get({ page: Number(pageNumber) + 1, users_id: userId });
            setList(response.data.data);
        } catch (error) {

            console.error("Error fetching emergency contacts:", error);
        } finally {
            setLoading(false);
        }
    };



    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setID(0)
        reset()
    };


    const handleDelete = (id) => {
        setID(id)
        setDeletePopup(true)
    }


    const handleEdit = async (id) => {
        setLoading(true)
        setID(id)
        setOpen(true)
        let EmergencyContactDetails = await EmergencyContactsAPI.getDetails({ id: id });
        if (EmergencyContactDetails.data.status === "success") {
            let data = EmergencyContactDetails.data.data;
            setValue('contact_name', data.contact_name);
            setValue('phone_number', data.phone_number);
            setValue('relationship', data.relationship);
        }
        setLoading(false)
    };


    const handleFormSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true });


        let dataToSubmit = {
            contact_name: data.contact_name,
            phone_number: data.phone_number,
            relationship: data.relationship
        }
        let action;
        if (ID > 0) {
            dataToSubmit.id = ID
            action = EmergencyContactsAPI.update(dataToSubmit);
        } else {
            dataToSubmit.users_id = userId
            action = EmergencyContactsAPI.add(dataToSubmit);

        }

        action.then(response => {
            setFormButtonStatus({ label: "Submit", loading: false, disabled: true });
            if (response.data.errors) {
                toast.error("The email or phone number has already been taken")
                return
            }
            toast.success(response.data.message)
            // props.onUpdate();
            // setOpen(false)
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            handleClose()
            fetchEmergencyContacts()
        }).catch(errors => {
            toast.error("Internal server error");
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            // setOpen(false)
        })

    };

    const handlePageChange = (page) => {
        setPageNumber(page)
    }


    return (
        <div>

            {showDeletePopup && <Delete
                ID={ID}
                setID={setID}
                setDeletePopup={setDeletePopup}
                Callfunc={() => fetchEmergencyContacts()}
                url="/employees/emergency-contacts/delete?id="
                title="Emergency Contact" />}

            <div className='flex' style={{ width: '100', display: 'flex', justifyContent: "end", marginRight: 10 }}>
                < Button variant="contained"
                    className='create-btn'
                    sx={{ width: "200px", }}
                    onClick={handleOpen} >Add Emergency Contact</Button >
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ textAlign: "center", pb: 5 }}>
                        Emergency Contact Form
                    </Typography>
                    {loading ? (
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '30vh'
                        }}>
                            <CircularProgress className='ld-circle' />
                        </Box >
                    ) : (
                        <>
                            <form onSubmit={handleSubmit(handleFormSubmit)}>

                                <Grid mb={2}>
                                    <TextInput
                                        control={control}
                                        name="contact_name"
                                        label="Contact Name"
                                        value={watch('contact_name')}
                                    />
                                </Grid>

                                <Grid mb={2}>
                                    <TextInput
                                        control={control}
                                        name="phone_number"
                                        label="Phone Number"
                                        value={watch('phone_number')}
                                    />
                                </Grid>

                                <TextInput
                                    control={control}
                                    name="relationship"
                                    label="Relationship"
                                    value={watch('relationship')}
                                />
                                <div style={{ display: "flex", justifyContent: "end" }}>
                                    <Button
                                        variant="outlined"
                                        type="submit"
                                        color='error'
                                        sx={{
                                            mt: 2, mr: 2,

                                        }}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>

                                    <LoadingButton
                                        sx={{
                                            mt: 2, mr: 2,

                                        }}
                                        loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                        variant="outlined" color='success' type="submit">{formButtonStatus.label}</LoadingButton>
                                </div>
                            </form>
                        </>
                    )}
                </Box>
            </Modal>
            {loading ?
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                    <CircularProgress className='ld-circle' size={33} />
                    <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                        Loading...
                    </Typography>
                </Box> : (
                    <>
                        <Card sx={{ mt: 5 }} variant="outlined">
                            {typeof list.data === "object" &&
                                <DataGrid
                                    paginationMode="server"
                                    rows={list?.data}
                                    columns={columns}
                                    page={pageNumber}
                                    pageSize={10}
                                    rowHeight={20}
                                    rowsPerPageOptions={[10]}
                                    rowCount={list.total}
                                    onPageChange={handlePageChange}
                                    autoHeight
                                    density={"comfortable"}
                                    getRowHeight={() => 35}

                                />
                            }

                        </Card>
                    </>
                )
            }
        </div>


    )
}

export default EmergencyContact
