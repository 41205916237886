import { get, post } from './../Config/config';

export const Documentcategory = {

    get: (data) => get('/api/document-categories', { params: data }),
    add: (data) => post('/api/document-categories/store', data),
    update: (data) => post(`/api/document-categories/update`, data),
    getDetails: (data) => get('/api/document-categories/view?id=2',{ params: data }),
    delete: (data) => get(`/api/document-categories/delete?id=${data} `),
}
