import { get, post } from './../Config/config';


export const Document = {
    get: (data) => get('/hr/documents', { params: data }),
    add: (data) => post('/hr/documents/store', data),
    getDetails: (data) => get('/hr/documents/view?id=', { params: data }),
    update: (data) => post('/hr/documents/update', data),
    documentPermssion: (data) => post('hr/documents/permissions', data),
    deleteDocumentPermissions: (data) => post('/hr/documents/permissions/delete', data),
}