import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import {
    Button,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
} from "@mui/material";

function Index({ setSelectedFiles, selectedFiles, text, Accepttype }) {

    const addFile = (event) => {
        const file = event.target.files[0];
        setSelectedFiles(file);
    };


    const handleRemoveFile = () => {
        setSelectedFiles(null);
    };

    return (
        <div>
            <Box pt={2}>
                <input
                    type="file"
                    id="file-upload"
                    accept={Accepttype}
                    style={{ display: 'none' }}
                    onChange={addFile}

                />

                <label htmlFor="file-upload">
                    <Button
                        variant="contained"
                        style={{ backgroundColor: 'rgb(63,197,149)', }}
                        startIcon={<CloudUploadIcon />}
                        component="span"
                        sx={{
                            mt: 2,
                        }}
                    >
                        {text}
                    </Button>
                </label>

                <Box sx={{ maxHeight: 150, overflowY: 'auto', mt: 2, }}>
                    <List >
                        {selectedFiles && (
                            <ListItem sx={{ backgroundColor: 'rgb(230, 245, 233)', mt: 1 }}>
                                <ListItemIcon>
                                    <CloudUploadIcon />
                                </ListItemIcon>
                                <ListItemText primary={selectedFiles.name} />
                                <IconButton
                                    startIcon={<DeleteIcon />}
                                    edge="end"
                                    onClick={() => handleRemoveFile(selectedFiles)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                        )}

                    </List>
                </Box>
            </Box>
        </div >
    );
}

export default Index;
