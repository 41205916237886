import { get, post } from './../Config/config';

export const Selfi = {

    get: (data) => get('/employees/photos', { params: data }),
    addSelfie: (data) => post('/employees/update-photo', data),

    addFamilyPhoto: (data) => post('/employees/update-photo', data),



    // Delete

    removePhoto: (data) => post('/employees/remove-photo', data)
}
