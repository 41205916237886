import React, { useState } from 'react';
// import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, Typography } from "@mui/material";
import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, Slide, Typography } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import TextInput from '../../../form/TextInput'
import { Departments } from '../../../../api/Endpoints/Departments';
import { LoadingButton } from "@mui/lab";
import { toast } from 'react-toastify';
import { ErrorMessage } from "@hookform/error-message";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from 'react';
import '../../../../Custom.css'
import { DotSpinner } from '@uiball/loaders';
import SelectX from '../../../form/SelectX';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const scheme = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),

});


const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)


    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            props.setEditId(undefined)
        }, 100)

    };


    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });




    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true });

        let dataToSubmit = {
            id: props?.editId,
            users_id: props?.editId,
            name: data.name,
            description: data.description,


        };

        console.log(dataToSubmit)

        let action;
        if (props.editId > 0) {

            console.log("this datatosubmit", dataToSubmit);
            action = Departments.update(dataToSubmit);

        } else {
            console.log("this datatosubmit", dataToSubmit);
            action = Departments.add(dataToSubmit);

        }

        action.then(response => {
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            if (response.data.errors) {
                let err = response.data.errors
                Object.keys(err).forEach((key) => {
                    toast.error(err[key].name, { autoClose: 3000 });
                });
                return
            }

            toast.success(response.data.message, { autoClose: 3000 })
            props.onUpdate();
            setOpen(false)
        }).catch(errors => {
            toast.error("Internal server error", { autoClose: 3000 });
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: true });
            setOpen(false)
        })
    }



    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchDetails()
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);
        }

    }, [props.editId])



    const fetchDetails = async () => {
        setLoading(true)
        let DepartmentDetails = await Departments.getDetails({ id: props.editId });
        if (DepartmentDetails.data.status === "success") {
            let data = DepartmentDetails.data.data;
            setValue('name', data.name);
            console.log(data.name);
            setValue('description', data.description);
            setLoading(false)
        }

    }



    return (
        <div>
            <Button
                className='create-btn'
                variant="contained"
                onClick={handleClickOpen}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <AddIcon sx={{ marginRight: '2px' }} />  {/* Add a margin to the icon if needed */}
                Add Department

            </Button>
            <Dialog
                open={open}
                PaperProps={{ sx: { width: "40%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Department" : "Add Department"}</DialogTitle>


                {loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <DotSpinner
                            size={47}
                            speed={0.9}
                            color="rgb(63,197,149"
                        />
                    </Box >
                ) : (
                    <>
                        <DialogContent sx={{ p: 2, mt: 3 }}>
                            <Grid container >

                                <Grid item xs={12} sm={12}>


                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Grid container>
                                            <Grid sx={{ p: 1 }} item xs={12}>
                                                <TextInput control={control} name="name"
                                                    label="Name"
                                                    value={watch('name')} />
                                            </Grid>
                                            <Grid item xs={6} sm={12} mb={1} p={1}>
                                                <SelectX
                                                    defaultOptions
                                                    label={"Department Head"}
                                                    options={null}
                                                    control={control}
                                                    error={errors.message}
                                                    name={'department_head'}
                                                    defaultValue={watch('department_head')}
                                                />
                                            </Grid>
                                            <Grid sx={{ p: 1 }} item xs={12}  >
                                                <TextInput control={control} name="description"
                                                    label="Description"
                                                    value={watch('description')}
                                                    isMultiline
                                                    row={5}
                                                />
                                            </Grid>
                                            <Grid sx={{ p: 1 }} item xs={12}>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="multipleErrorInput"
                                                    render={({ messages }) =>
                                                        messages &&
                                                        Object.entries(messages).map(([type, message]) => (
                                                            <p key={type}>{message}</p>
                                                        ))
                                                    }
                                                />

                                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                                    variant="outlined" color='success' type="submit">{formButtonStatus.label}</LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </form>






                                </Grid>
                                <Grid item xs={4}>

                                </Grid>


                            </Grid>



                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant='outlined' color='error' >Close</Button>

                        </DialogActions>

                    </>
                )}
            </Dialog>
        </div >

    );
};

export default Create;