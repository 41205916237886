import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeader from "../../common/page-header";
import SearchField from "../../../../components/searchEntry/Index";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import { useForkRef } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Employee } from "../../../../api/Endpoints/Employee";
import { Waveform } from "@uiball/loaders";
import moment from "moment";
import Create from "./create";
function EmployeeEvaluationTemplate() {
  const { watch } = useForm();
  const [list, setList] = useState([]);
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(false);
  const [TableUpdate, setTableUpdate] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [editId, setEditId] = useState();
  const [CreateEmployeeTab, setCreateEmployeeTab] = useState(false)

  const columns = [
    {
      field: "name",
      headerName: "Title",
      width: 350,
      renderCell: (params) => (
        <>
          <Typography
            sx={{ color: "rgb(0,158,102)", fontWeight: 500, cursor: "pointer" }}
          >
            {params.value}
          </Typography>
        </>
      ),
    },
    {
      field: "last_login",
      headerName: "Created Date",
      width: 350,
      renderCell: (params) => {
        return params.value ? moment(params.value).format("DD-MM-YYYY") : "";
      },
    },
    {
      field: "edit",
      headerName: "Edit",

      width: 100,
      renderCell: (params) => (
        <>
          <Button
            size="small"
            onClick={() => handleEdit(params.row.employee_id)}
          >
            <EditIcon style={{ color: "gray", height: 18 }} />
          </Button>
        </>
      ),
    },
  ];

  const fetchTable = (retryCount = 0) => {
    const maxRetries = 3;
    if (!TableUpdate) {
      setLoading(true);
    }
    Employee.get({
      keyword: searchKeyword,
      page: Number(pageNumber) + 1,
      employee_role: watch("employee_role")?.id,
      employee_level: watch("employee_level"),
      employee_type: watch("employee_type"),
      limit: 20,
    })
      .then((response) => {
        setList(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        if (retryCount < maxRetries) {
          console.error(error);
          fetchTable(retryCount + 1);
        } else {
          setLoading(false);
          toast.error(
            "An error occurred. Please try again later or contact the administrator.",
            { autoClose: 6000 }
          );
        }
      })
      .finally(() => {
        if (TableUpdate) {
          setTableUpdate(false);
        }
      });
  };
  useEffect(() => {
    fetchTable();
  }, []);
  const handleSearchEntry = (event) => {
    setSearchKeyword(event.target.value);
    // setSearchEntry(event.target.value)
    setTableUpdate(true);
  };
  const handlePageChange = (page) => {
    setPageNumber(page);
  };
  const handleEdit = (id) => {
    setEditId(parseInt(id));
  };
  const handleCreate = () => {
    setEditId(0)
    setRefresh(Math.random)
}
const ForTableUpdate = () => {
    setTableUpdate(true)
    fetchTable()
}
  return (
    <div>
      <Card
        sx={{
          p: 1,
          display: "flex",
          justifyContent: "space-between",
          mb: 1,
          ml: 3,
          mt: 1,
          boxShadow: 0,
          backgroundColor: "transparent",
        }}
      >
        <PageHeader title={"Employee Evaluation Templates"} />

        <Create
          key={refresh}
          // onNew={handleCreate}

          onUpdate={ForTableUpdate}
          editId={editId}
          onNew={handleCreate}
          setEditId={setEditId}
          setCreateEmployeeTabs={setCreateEmployeeTab}
          CreateEmployeeTabs={CreateEmployeeTab}
        />
      </Card>
      {!TableUpdate && loading ? (
        <>
          <Box id="ui-ball-loader">
            <Waveform
              size={50}
              lineWeight={3.5}
              speed={1}
              color="rgb(63,197,149"
            />
          </Box>
        </>
      ) : (
        <Card sx={{ m: 2 }} variant="outlined">
          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            pt={1}
            xs={12}
            sm={12}
          >
            <SearchField
            // clearSerachInput={clearSerachInput}
            // serachEntry={serachEntry}
            // handleSearchEntry={handleSearchEntry}
            />
          </Grid>
          {list?.data?.length > 0 ? (
            <>
              {typeof list.data === "object" && (
                <DataGrid
                  paginationMode="server"
                  rows={list?.data}
                  columns={columns}
                  page={pageNumber}
                  pageSize={20}
                  rowHeight={20}
                  rowsPerPageOptions={[10]}
                  rowCount={list.total}
                  onPageChange={handlePageChange}
                  autoHeight
                  density={"comfortable"}
                  getRowHeight={() => 35}
                />
              )}
            </>
          ) : (
            <Box id="data-not-found-msg">
              <Typography alignSelf={"center"}>Employee not found.</Typography>
            </Box>
          )}
        </Card>
      )}
    </div>
  );
}

export default EmployeeEvaluationTemplate;
