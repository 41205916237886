import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import "../../../../../Custom.css"
import {

    Card,
    CardContent,
    Typography,
    Grid,
    Divider,
    CircularProgress,
    Box,
} from "@mui/material";
import { useEffect } from 'react';
import { useState } from 'react';
import { Documentcategory } from '../../../../../api/Endpoints/Documentcategory';

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        border: '1px solid #ccc ',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));

const Details = ({ ID }) => {
    const classes = useStyles();


    const [Loading, setLoading] = useState(false)
    const [Data, setData] = useState()
   
    useEffect(() => {
        fetchDetails()
    }, [ID])

    const fetchDetails = async () => {
        setLoading(true);
        let DocumentCategoryDetails = await Documentcategory.getDetails({ id: ID });
        let data = DocumentCategoryDetails.data.data
        setData(data)
        setLoading(false);
    }
    return (
        <>
            {Loading ? (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '30vh'
                }}>
                    <CircularProgress className='ld-circle' />
                </Box >
            ) : (
                <>
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Name:

                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.name}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Remarks:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.remarks}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>





                        </CardContent>
                    </Card>
                </>
            )
            }
        </>
    );
};

export default Details;