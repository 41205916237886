import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
    Button,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
    Typography,
} from "@mui/material";
import PdfPopup from '../Popups/PdfView';
import Delete from '../Popups/Delete';

function Index({ setSelectedFiles, selectedFiles, err, ID, EditID, setEditID, CallFunc }) {

    const [showDeletePopup, setDeletePopup] = useState(false)
    const [DocID, setDocID] = useState(0)
    const addFile = (event) => {
        setEditID(0)
        const file = event.target.files[0];
        setSelectedFiles(file);
    };

    const handleRemoveFile = () => {
        setSelectedFiles(null);


    };


    const DeleteDocumentHandler = () => {
        setDeletePopup(true)
        setDocID(EditID)
    }
    return (
        <div>


            {showDeletePopup &&
                <Delete
                    ID={DocID}
                    setID={setDocID}
                    setDeletePopup={setDeletePopup}
                    Callfunc={() => CallFunc()}
                    url="/hr/documents/delete-file?id="
                    title="File" />}
            <Box>

                <input
                    type="file"
                    id="file-upload"
                    style={{ display: 'none' }}
                    onChange={addFile}
                />
                {ID ?
                    null

                    : (


                        <label htmlFor="file-upload">
                            <Button
                                variant="contained"
                                color='success'
                                startIcon={<CloudUploadIcon />}
                                component="span"
                                sx={{
                                    mt: 2,

                                }}
                            >
                                Upload Document
                            </Button>
                        </label>

                    )}
                {err && !selectedFiles && <Typography sx={{ color: "red", mt: 1 }}>{err}</Typography>}
                <Box sx={{ maxHeight: 150, overflowY: 'auto', mt: 2, }}>
                    <List >
                        {selectedFiles && (
                            <ListItem sx={{ backgroundColor: 'rgb(230, 245, 233)', mt: 1 }}>
                                <ListItemIcon>
                                    <CloudUploadIcon />
                                </ListItemIcon>
                                <ListItemText primary={ID || EditID ? selectedFiles : selectedFiles.name} />

                                {ID || EditID ? (
                                    <>

                                        <PdfPopup documentURL={selectedFiles} EditID={EditID} />

                                    </>
                                ) : (
                                    <>
                                        <IconButton
                                            startIcon={<DeleteIcon />}
                                            edge="end"
                                            onClick={() => handleRemoveFile(selectedFiles)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </>
                                )}
                                {EditID ?
                                    (
                                        <IconButton
                                            startIcon={<DeleteIcon />}
                                            edge="end"
                                            onClick={() => DeleteDocumentHandler(selectedFiles)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )
                                    : (
                                        null
                                    )
                                }
                            </ListItem>
                        )}

                    </List>
                </Box>
            </Box>
        </div >
    );
}

export default Index;
