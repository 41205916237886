import { get, post } from '../Config/config';

export const Holidaycalerndar= {

    get: (data) => get('/hr/holiday-calendar', { params: data }),
    add: (data) => post('/hr/holiday-calendar/store', data),
    getDetails: (data) => get('/hr/holiday-calendar/view?id=', { params: data }),
    delete: (data) => get(`/hr/holiday-calendar/delete?id=${data} `),
    update: (data) => post(`/hr/holiday-calendar/update`, data)
}
