import React, { useState } from 'react'
import AddIcon from "@mui/icons-material/Add";
import Select from 'react-select';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { skillshobbies } from '../../../../../api/Endpoints/skill&hobbies'
import {
    Backdrop,
    Button,
    Card,
    CircularProgress,
    Divider,
    List,
    Box,
    Modal,
    IconButton,
    ListItemIcon,
    TextareaAutosize,
    ListItem,
    ListItemText,
    InputLabel,
    TextField,
    Typography
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useStateContext } from '../../../../../contexts/SignIn/UsersID/usersID';
import Delete from '../../../../../components/Popups/Delete';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '80vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const options = [
    { value: 'option1', label: 'Hobbie' },
    { value: 'option2', label: 'Learning' },
    { value: 'option3', label: 'Intermediate' },
    { value: 'option4', label: 'Professional' },
    { value: 'option5', label: 'Master' },
];

function SkillandHobbies() {

    const [open, setOpen] = useState(false);
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false);
    const [titleText, settitleText] = useState('');
    const [ExprtaiseLevel, setExprtaiseLevel] = useState('');
    const [PopLoading, setPopupLoading] = useState(false)
    const [textError, setTextError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [ID, setID] = useState(0)
    const [showDeletePopup, setDeletePopup] = useState(false)
    const { userId } = useStateContext();

    console.log("This is users id", userId);
    const columns = [
        { field: 'title', headerName: 'Title', width: 200 },
        { field: 'expertise', headerName: 'Expertise', width: 500 },
        {
            field: 'Edit',
            headerName: 'Actions',
            width: 230,
            renderCell: (params) => (
                <>


                    <Button size="small" onClick={() => handleEditButtonClick(params.row.id)}>
                        <EditIcon style={{ color: 'blue', height: 19 }} />
                    </Button>
                    <Button size='small' onClick={() => handleDeleteButtonClick(params.row.id)}>
                        <DeleteIcon style={{ color: 'red', height: 19 }} />
                    </Button>

                </>
            ),
        },
    ];
    const handleTextChange = (event) => {
        settitleText(event.target.value);
        setTextError('')
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setExprtaiseLevel('')
        settitleText('')
        setTextError('')
        setStatusError('')
        setID(0)
        setOpen(false);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!titleText.trim() && !ExprtaiseLevel) {
            setTextError('field required');
            setStatusError('Please select a status');
            return
        } else if (!ExprtaiseLevel) {
            setStatusError('Please select a status');
            return
        }
        else if (!titleText.trim()) {
            setTextError('Document title is required');
            return
        }

        const dataToSubmit = {
            title: titleText,
            expertise: ExprtaiseLevel,
        }
        let action
        if (ID > 0) {
            dataToSubmit.id = ID
            action = await skillshobbies.update(dataToSubmit)
        } else {
            dataToSubmit.users_id = userId
            action = await skillshobbies.add(dataToSubmit)
        }
        if (action.data.message === "Skill has been successfully updated.") {

        }

        fetchTable()
        handleClose();
    };
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            padding: '3px', // set the padding value to 10px
            height: '5px'
        })
    };
    useEffect(() => {
        fetchTable()
    }, []);


    const fetchTable = () => {
        setLoading(true);
        skillshobbies.get({ users_id: userId })
            .then(response => {
                setList(response.data.data)
                // setUser({ name: response.data.data.name, email: response.data.data.email });
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });

    }

    const handleEditButtonClick = async (id) => {
        setOpen(true);
        setPopupLoading(true);
        setID(id);
        const response = await skillshobbies.getDetails({ id: id });
        const data = response.data.data;
        settitleText(data.title);
        setExprtaiseLevel(data.expertise); // Ensure data.expertise is a string or set it to an empty string
        setPopupLoading(false);
    };

    const handleDeleteButtonClick = async (id) => {
        setID(id)
        setDeletePopup(true)

    }

    return (
        <div>
            {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                    <CircularProgress className='ld-circle' size={33} />
                    <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                        Loading...
                    </Typography>
                </Box>) : (
                <>

                    <Box width={'95%'} display={'flex'} justifyContent={'end'}>
                        <Button
                            className="create-btn"
                            variant="contained"
                            onClick={handleOpen}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <AddIcon sx={{ marginLeft: "2px" }} />
                            Add Salary
                        </Button>
                    </Box>
                    {showDeletePopup && <Delete
                        ID={ID}
                        setID={setID}
                        setDeletePopup={setDeletePopup}
                        showDeletePopup={showDeletePopup}
                        Callfunc={() => fetchTable()}
                        url="/employees/skills/delete?id="
                        title="Skill" />}


                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >

                        <form onSubmit={handleSubmit}>
                            <Box sx={style}>
                                {PopLoading ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                                        <CircularProgress size={33} />
                                        <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                                            Loading...
                                        </Typography>
                                    </Box>
                                ) : (
                                    <>



                                        <InputLabel sx={{
                                            color: "black",

                                        }}>Enter Skill or Hobbie</InputLabel>
                                        <TextField
                                            aria-label="empty textarea"
                                            multiline
                                            value={titleText}
                                            onChange={handleTextChange}
                                            autoFocus
                                            style={{ width: "100%", marginTop: 8, }}
                                        />
                                        {textError && <p style={{ color: 'red' }}>{textError}</p>}
                                        <Box mt={2}>

                                            <InputLabel sx={{
                                                color: "black",
                                                pb: 1,
                                            }}>Expertise Level</InputLabel>
                                            <Select
                                                placeholder={'sfklsdhfsdfsd'}
                                                options={options}
                                                required
                                                name='skillandhobbie'
                                                styles={customStyles}
                                                value={{ label: ExprtaiseLevel, value: ExprtaiseLevel }}
                                                onChange={(selectedOption) => {
                                                    setExprtaiseLevel(selectedOption.label)
                                                    setStatusError('')
                                                }}
                                            />
                                        </Box>
                                        {statusError && <p style={{ color: 'red' }}>{statusError}</p>}
                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                sx={{
                                                    mt: 2, mr: 2,
                                                    background: "gray",
                                                    '&:hover': {
                                                        backgroundColor: 'gray',
                                                    },
                                                }}
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </Button>

                                            <Button variant="contained" type="submit" sx={{ mt: 2 }}>
                                                Submit
                                            </Button>

                                        </div>

                                    </>
                                )}
                            </Box>
                        </form>

                    </Modal>



                    <Card sx={{ mt: 5 }} variant="outlined">
                        {typeof list?.data === "object" &&
                            <DataGrid
                                paginationMode="server"
                                rows={list?.data}
                                columns={columns}
                                // page={pageNumber}
                                pageSize={10}
                                rowHeight={20}
                                rowsPerPageOptions={[10]}
                                // onPageChange={handlePageChange}
                                autoHeight
                                density={'comfortable'}
                                getRowHeight={() => 35}
                            />
                        }

                    </Card>
                </>
            )
            }
        </div >
    )
}

export default SkillandHobbies
