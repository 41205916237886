
import { Departments } from "../../api/Endpoints/Departments";
import { Employee } from "../../api/Endpoints/Employee";
import { EmployeeRole } from "../../api/Endpoints/EmployeeRoles";


export const fetchEmployees = async (e) => {
    const response = await Employee.get({ keyword: e });
    if (typeof response.data.data.data !== "undefined") {
        return response.data.data.data;
    } else {
        return [];
    }
}


// export const fetchEmployeeRoles = async (e) => {
//     const response = await Employee.getRoles({ keyword: e });
//     if (typeof response.data.data.data !== "undefined") {
//         return response.data.data.data;
//     } else {
//         return [];
//     }
// }

export const fetchEmployeeRoles = async (e) => {
    let allRoles = [];
    let currentPage = 1;
    let hasMorePages = true;

    while (hasMorePages) {
        try {
            const response = await EmployeeRole.get({ keyword: e, page: currentPage });
            const pageData = response.data.data;
            allRoles = [...allRoles, ...pageData.data];
            if (pageData.current_page < pageData.last_page) {
                currentPage++;
            } else {
                hasMorePages = false;
            }
        } catch (error) {
            console.error("Error fetching roles:", error);
            hasMorePages = false;
        }
    }

    return allRoles;
};



export const fetchDepartments = async (e) => {
    const response = await Departments.get({ keyword: e });
    if (typeof response.data.data.data !== "undefined") {
        return response.data.data.data;
    } else {
        return [];
    }
}