import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Slide, Typography } from "@mui/material";
import { useEffect } from 'react';
import { Close as CloseIcon } from "@mui/icons-material";
import Details from './Tab/Details.js';
import "../../../../Custom.css"
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const View = ({ ID, setID, setDetails }) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setOpen(false);

        setTimeout(() => {
            setID(0)
            setDetails(false)

        }, 200)

    };



    useEffect(() => {
        if (ID > 0) {
            setOpen(true);
        }
    }, [ID]);

    return (
        <div>
            <Dialog
                open={open}
                PaperProps={{
                    sx: {
                        width: "35%",
                        height: "100%",
                        position: "fixed",
                        right: 10,
                        top: 10,
                        bottom: 0,
                        m: 0,
                        p: 0,
                        borderRadius: "10px 10px 0 0", // Set top-left and top-right border radius
                        maxHeight: '100%',
                    },
                }}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                TransitionComponent={Transition}
                keepMounted
            >
                <DialogContent sx={{ p: 2 }}>
                    <Box className="viewmodal"
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 3, p: 0 }}>
                        <Typography sx={{ fontWeight: 600, fontSize: 20, }}>LeaveManagement Details</Typography>
                        <IconButton onClick={handleClose} sx={{ backgroundColor: 'rgb(247,247,248)' }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box pt={5}>
                        {ID && <Details ID={ID} />}
                    </Box>

                </DialogContent>

            </Dialog>
        </div >
    );
};

export default View;