import { Box, Container, Link, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import config from '../config.json'
const links = [
  {
    label: 'SpiderWorks.in',
    href: 'https://spiderworks.in/'
  }
];



export const Footer = () => {

  const CurrentDate = new Date().getFullYear()



  // Define a function to fetch the configuration data

  console.log("this is responser", config);

  return (
    <div>
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: {
            sm: 'row',
            xs: 'column'
          },
          py: 3,
          '& a': {
            mt: {
              sm: 0,
              xs: 1
            },
            '&:not(:last-child)': {
              mr: {
                sm: 5,
                xs: 0
              }
            }
          }
        }}
      >
        <Typography
          color="textSecondary"
          variant="caption"
        >
          © {`${CurrentDate}`} SpiderWorks<br />
          <Typography variant="caption" sx={{ ml: 1 }}>version : {config?.dashboardVersion}</Typography>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {links.map((link) => (
          <Link
            color="textSecondary"
            href={link.href}
            key={link.label}
            target="_blank"
            underline="none"
            variant="body2"
          >
            {link.label}
          </Link>
        ))}
      </Container>
    </div>
  );
} 