import React, { useState } from "react";
import GroupIcon from '@mui/icons-material/Group';
import { Fragment } from "react";
import { FormControl, FormControlLabel, IconButton, Menu, MenuItem, Radio, RadioGroup } from "@mui/material";
import EmployeesListPopup from "./EmployeesListPoup";
import { Document } from "../../api/Endpoints/Document";
import { toast } from "react-toastify";
import PublicIcon from '@mui/icons-material/Public';


const PermissionKey = ({ params, refresh }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedValue, setSelectedValue] = useState(params.permission || "Public"); // Set the default based on params.permission


    const [shwopopup, setShowpopup] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleRadioChange = async (event) => {

        setSelectedValue(event.target.value);

        if (event.target.value === "Private") {
            setShowpopup(true);
            setAnchorEl(null)
            return;
        }

        const dataToSubmit = {
            id: params.id,
            permission: "Public"
        }

        let action;
        action = await Document.documentPermssion(dataToSubmit)
        if (action.data.status) {
            toast.success('Document permissions has been successfully saved.', { autoClose: 1000 })
            handleClose()
            refresh(Math.random)
        } else {
            toast.error('Failed to save document permissions. Please try again later.', { autoClose: 5000 })
        }
    };

    return (
        <Fragment>

            {params.permission !== "Private" && shwopopup &&
                <EmployeesListPopup
                    setOpen={setShowpopup}
                    Radio={selectedValue === "Private"}
                    id={params.id}
                    setSelectedValue={setSelectedValue}
                    refresh={refresh}
                />}
            {params?.permission === "Public" ? (
                <IconButton size="small" onClick={handleClick}>
                    <PublicIcon style={{ color: "gray", height: 19 }} />
                </IconButton>
            ) : (
                <IconButton size="small" onClick={handleClick}>
                    <GroupIcon style={{ color: "gray", height: 19 }} />
                </IconButton>

            )}
            {/* <IconButton size="small" onClick={handleClick}>
                <VpnKeyIcon style={{ color: "gray", height: 19 }} />
            </IconButton> */}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <FormControl sx={{ padding: 3 }}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={selectedValue}
                        name="radio-buttons-group"
                        onChange={handleRadioChange}
                    >
                        <MenuItem class="edit-nav-cntr">
                            <FormControlLabel
                                value="Public"
                                checked={selectedValue === "Public"}
                                control={<Radio />}
                                label="Public" />
                        </MenuItem>
                        <MenuItem class="edit-nav-cntr delete-sec">
                            <FormControlLabel
                                value="Private"
                                checked={selectedValue === "Private"}
                                control={<Radio />}
                                label="Private" />
                        </MenuItem>
                    </RadioGroup>
                </FormControl>
            </Menu>
        </Fragment>
    );
};

export default PermissionKey;
