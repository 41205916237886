import React, { useState } from 'react';
import { Button, Popover, Box, Typography, TextField, MenuItem, Dialog, DialogContent, Grid, Divider } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Employee } from '../../api/Endpoints/Employee';
import SelectX from '../../project-portal/form/SelectX';
import TextInput from '../Form/TextInput';
import DateInput from '../../project-portal/form/DateInput';

const Winner = ({ showWinners, setShowWinners }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDropdown1, setSelectedDropdown1] = useState('');
    const [selectedDropdown2, setSelectedDropdown2] = useState('');
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm();
    const fetchEmployeeId = (e) => {
        return Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data
                // return [{ id: 1, name: "1001" },
                // { id: 1, name: "1002" }
                // ];
            } else {

                return []
            }
        })
    }
    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopupClose = () => {
        setShowWinners(false)
    };

    const handleDropdown1Change = (event) => {
        setSelectedDropdown1(event.target.value);
    };

    const handleDropdown2Change = (event) => {
        setSelectedDropdown2(event.target.value);
    };

    return (
        <>

            <Dialog
                open={showWinners}
                onClose={handlePopupClose}
                fullWidth={true}
                PaperProps={{
                    style: {
                        minHeight: '530px',
                        minWidth: '100px',
                    },
                }}
            >
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'row', padding: 2, justifyContent: 'space-around', height: '200px' }}>
                        <Box sx={{ flex: 1, paddingTop: '16px' }}>
                            <Typography variant="h6" gutterBottom>
                                Winners
                            </Typography>
                            <Box mt={3}>
                                <Typography fontSize={'15px'} m={1}>ASHFAK</Typography>
                                <Typography fontSize={'15px'} m={1}>ASHFAK</Typography>
                                <Typography fontSize={'15px'} m={1}>ASHFAK</Typography>
                            </Box>

                            {/* Add more sample user details as needed */}
                        </Box>

                        <Grid sx={{ flex: 1, paddingTop: '15px', paddingLeft: 5, borderLeft: '1px solid #ccc', height: 'fit-content;' }}>
                            <Typography variant="h6" gutterBottom>
                                Add Winners
                            </Typography>
                            {/* Dropdown 1 */}
                            <Box item xs={6} mt={3}>
                                <SelectX
                                    control={control}
                                    name="employees_id"
                                    label="Winner"
                                    value={watch('employees_id')}
                                    options={fetchEmployeeId}
                                    defaultValue={watch('employees_id')} />

                            </Box>
                            <Box sm={12} mt={2}>
                                <DateInput
                                    control={control}
                                    name="selection_date"
                                    label="Selection date"
                                    value={watch('selection_date')}
                                />
                            </Box>
                            <Box sm={12} mt={3}>
                                <TextInput control={control} name="description"
                                    label="Description"
                                    isMultiline
                                    row={7}
                                    value={watch('title')} />
                            </Box>
                        </Grid>
                    </Box>
                </DialogContent >
                <Grid p={2} display={'flex'} justifyContent={'end'}>
                    <Button variant='outlined' color='error' sx={{ mr: 1 }} onClick={handlePopupClose}>Cancel</Button>
                    <Button variant='outlined' color='success' onClick={handlePopupClose}>Update</Button>
                </Grid>
            </Dialog >
        </>
    );
};

export default Winner;
