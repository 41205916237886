import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card, Grid, Typography, Divider, IconButton } from '@mui/material';
import Delete from '../Popups/Delete';
import { useState } from 'react';
import EditPopup from '../Popups/EditNotes';
import { NotesAPI } from '../../api/Endpoints/Notes';
import { toast } from 'react-toastify';

const NoteCard = ({ note, fetchNotes }) => {
    const [showDeletePopup, setDeletePopup] = useState(false);
    const [showEditpopup, setEditpopup] = useState(false)
    const [ID, setID] = useState(0);
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Save",
        loading: false,
        disabled: false,
    });
    const [NoteData, setData] = useState({
        ID: 0,
        note: ''
    })
    const handleDelete = (id) => {
        setID(id);
        setDeletePopup(true);
    };

    const handleEdit = (note) => {
        setEditpopup(true)
        setData({
            ID: note.id,
            note: note.note
        })
    }

    const onSave = async () => {
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        let dataToSubmit = {
            id: NoteData.ID,
            note: NoteData.note
        }
        let response = await NotesAPI.update(dataToSubmit)
        toast.success(response.data.message, { autoClose: 1000 });
        setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        setID(false)
        setEditpopup(false)
        fetchNotes()
    }

    return (
        <>
            {showDeletePopup && (
                <Delete
                    ID={ID}
                    setID={setID}
                    setDeletePopup={setDeletePopup}
                    showDeletePopup={showDeletePopup}
                    Callfunc={() => fetchNotes()}
                    url="/employees/notes/delete?id="
                    title="Note"
                />
            )}

            {showEditpopup && (
                <EditPopup
                    note={NoteData}
                    editNote={setData}
                    showEditpopup={showEditpopup}
                    setEditpopup={setEditpopup}
                    onSave={onSave}
                    formButtonStatus={formButtonStatus}
                />
            )

            }
            <Card sx={{ p: 2, m: 1, }}>
                <Typography sx={{ fontWeight: 300 }}>{note.note}</Typography>
                <Divider sx={{ mt: 2 }} />
                <Grid container justifyContent="flex-end" sm={12}>
                    <IconButton onClick={() => handleEdit(note)}>
                        <EditIcon style={{ color: 'gray', height: 19 }} />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(note.id)}>
                        <DeleteIcon style={{ color: 'red', height: 19, marginLeft: 5 }} />
                    </IconButton>
                </Grid>
            </Card >
        </>
    );
};

export default NoteCard;
