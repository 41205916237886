import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Delete from "../../../../components/Popups/Delete";
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Divider,
    CircularProgress,
    Box,
    Dialog,
    DialogContent,
    Slide,
    IconButton,
} from "@mui/material";
import { useEffect } from 'react';
import { Close as CloseIcon } from "@mui/icons-material";
import { Document } from '../../../../api/Endpoints/Document';
import EmployeesListPoup from '../../../../components/Popups/EmployeesListPoup';
import Spinner from '../../../../components/Loader';
const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        border: '1px solid #ccc ',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const OverView = ({ setshowInfoPopup, ID, setID }) => {
    const classes = useStyles();
    const [Loading, setLoading] = useState(false);
    const [Data, setData] = useState();
    const [open, setOpen] = useState(false)
    const [shwopopup, setShowpopup] = useState(false);
    const [refresh, setRefresh] = useState(null)
    const [DocID, setDocID] = useState(0)
    const [showDeletePopup, setDeletePopup] = useState(false)
    useEffect(() => {
        if (ID) {
            setOpen(true)
            fetchDetails()
        }
    }, [ID, refresh])


    const handleClose = () => {
        setOpen(false)
        setID(0)
        setTimeout(() => {
            setshowInfoPopup(false)
        }, 100);

    }



    const fetchDetails = async () => {
        setLoading(true)
        const DocumentDetails = await Document.getDetails({ id: ID });
        if (DocumentDetails.data.status === "success") {
            let data = DocumentDetails.data.data;
            setData(data)
        } else {
            console.log("An error occured")
        }
        setLoading(false)
    }


    const addAccessList = () => {
        setShowpopup(true)
    }

    const PermissionDeleteHandler = (id) => {
        setDeletePopup(true)
        setDocID(id)
    }
    return (
        <>

            {showDeletePopup &&
                <Delete
                    ID={DocID}
                    setID={setDocID}
                    setDeletePopup={setDeletePopup}
                    Callfunc={() => fetchDetails()}
                    url="/hr/documents/permissions/delete"
                    title="accessee"
                    accessee={true}
                />

            }

            < Dialog
                open={open}
                PaperProps={{
                    sx: {
                        width: "35%",
                        height: "100%",
                        position: "fixed",
                        right: 10,
                        top: 10,
                        bottom: 0,
                        m: 0,
                        p: 0,
                        borderRadius: "10px 10px 0 0", // Set top-left and top-right border radius
                        maxHeight: '100%',
                    },
                }}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                TransitionComponent={Transition}
                keepMounted
            >
                <DialogContent sx={{ p: 2 }}>
                    <Box className="viewmodal"
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 3, p: 0 }}>
                        <Typography sx={{ fontWeight: 600, fontSize: 20, }}>Document Overview</Typography>
                        <IconButton onClick={handleClose} sx={{ backgroundColor: 'rgb(247,247,248)' }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box pt={5}>
                        {Loading ? (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '30vh',
                            }}>
                                <Spinner />
                            </Box>
                        ) : (
                            <>


                                {shwopopup && < EmployeesListPoup id={ID} refresh={setRefresh} setOpen={setShowpopup} ExistedUsers={Data?.permissions.map(users => users?.user?.id)} />}
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <Typography variant="body1" id='detail-text'>
                                                    Title:
                                                    <Typography variant="body2" id='detail-text-inline'>
                                                        {Data?.title}
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.divider} />
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <Typography variant="body1" component="h2" id='detail-text'>
                                                    Category:
                                                    <Typography variant="body2" id='detail-text-inline'>
                                                        {Data?.document_category?.name}
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <Typography variant="body1" component="h2" id='detail-text'>
                                                    Permission Type:
                                                    <Typography variant="body2" id='detail-text-inline' textTransform={'uppercase'}>
                                                        {Data?.permission}
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                        </Grid>   {Data?.permission === "Private" &&
                                            <Fragment>
                                                <Divider className={classes.divider} />
                                                <Grid container spacing={2} position={'relative'} >
                                                    <Box width={'100%'} display={'flex'} justifyContent={'end'} position={'absolute'}>
                                                        <IconButton onClick={addAccessList}>
                                                            <PersonAddAlt1Icon />
                                                        </IconButton>
                                                    </Box>

                                                    <Grid item xs={12} sm={12} m={2} pl={0}>
                                                        <Typography variant="body1" component="h2" id='detail-text'>
                                                            Access To:<br />
                                                            <Typography variant="body2" id='detail-text-inline'>
                                                                {Data?.permissions.length > 0 ? (
                                                                    <Box Box pl={0} display={'flex'} flexWrap={'wrap'} height={100} overflow={'auto'}>
                                                                        {Data?.permissions.map((users) => (
                                                                            <IconButton sx={{ padding: 1 }} id='permission-btn-list'>
                                                                                {users?.user?.name}
                                                                                <IconButton id='permission-delete-icon' sx={{ padding: 0 }} onClick={() => PermissionDeleteHandler(users.id)}>
                                                                                    <CloseIcon />
                                                                                </IconButton>

                                                                            </IconButton>

                                                                        ))}
                                                                    </Box>
                                                                ) : (
                                                                    <Typography maxWidth={350} >
                                                                        No permissions have been added.
                                                                    </Typography>
                                                                )
                                                                }
                                                            </Typography>
                                                        </Typography>
                                                    </Grid>


                                                </Grid>
                                            </Fragment>
                                        }
                                    </CardContent>
                                </Card>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <Typography variant="body1" component="h2" id='detail-text'>
                                                    Created By:
                                                    <Typography variant="body2" id='detail-text-inline' textTransform={'uppercase'}>
                                                        {Data?.created_user.name}

                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.divider} />
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <Typography variant="body1" component="h2" id='detail-text'>
                                                    Email:
                                                    <Typography variant="body2" id='detail-text-inline'>
                                                        {Data?.created_user?.email}
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </>
                        )}
                    </Box>
                </DialogContent>
            </ Dialog >
        </>
    );
};

export default OverView;