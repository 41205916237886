import { Backdrop, Grid, TextField, Button, Card, CircularProgress, Typography, InputAdornment, IconButton, Popover, Menu, MenuItem, Box } from "@mui/material";
import PageHeader from "../../common/page-header";
import Create from "./create";
import { useState } from "react";
import { Document } from "../../../../api/Endpoints/Document";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import * as React from 'react';
import Delete from "../../../../components/Popups/Delete";
import moment from "moment";
import PermissionKey from "../../../../components/Popups/PermissionKey";
import { MoreActionButton } from "../../common/MoreAction";
import OverView from "./OverView";
import { toast } from "react-toastify";
import { WaveformSpinner } from "../../../../components/Loader";
import { Fragment } from "react";
import SearchField from '../../../../components/searchEntry/Index'

const Documents = () => {

    const columns = [
        {
            field: 'title', headerName: 'Name', width: 450, renderCell: (params) => (

                <Typography sx={{ color: 'rgb(0,158,102)', fontWeight: 500 }} onClick={() => { DetailView(params.row.id) }}>{params.value}</Typography>

            )
        },
        {
            field: 'created_user',
            headerName: "Added by",
            width: 200,
            renderCell: (params) => <>{params?.row.created_user?.name}</>,
            headerClassName: 'customHeaderCell',
        },
        {
            field: 'created_at',
            headerName: "Uploaded Date",
            width: 150,
            renderCell: (params) => <>{moment(params.value).format('DD-MM-YYYY')}</>,
            headerClassName: 'customHeaderCell',
        },

        {
            field: 'Permssion',
            headerName: 'Permission',

            width: 120,
            renderCell: (params) => (
                <PermissionKey params={params.row} refresh={setRefresh} />
            )
        },

        {
            field: 'Actions',
            width: 20,
            renderCell: (params) => <MoreActionButton
                params={params}
                setID={setID}
                setDeletePopup={setDeletePopup}
                callEditFunc={handleEdit}
                OverView={true}
                setshowInfoPopup={setshowInfoPopup}
            />,
        },

    ];


    const [refresh, setRefresh] = useState(false)
    const [editId, setEditId] = useState()
    const [pageNumber, setPageNumber] = useState(0);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showCreateDocument, setCreateDocument] = useState(false)
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [ID, setID] = useState(0)
    const [UpdateID, setUpdateID] = useState(0)
    const [searchKeyword, setSearchKeyword] = useState();
    const [showInfoPopup, setshowInfoPopup] = useState(false);
    const [TableUpdate, setTableUpdate] = useState(false)
    const [serachEntry, setSearchEntry] = useState('')


    const handleUpdate = () => {
        setRefresh(Math.random)
    }

    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
        setSearchEntry(event.target.value)
        setTableUpdate(true)
    }

    const clearSerachInput = () => {
        setSearchEntry('');
        setSearchKeyword()
    }
    const handleEdit = (id) => {
        setRefresh(Math.random);
        setUpdateID(id)
        setEditId(parseInt(id));
        setCreateDocument(true)
    }


    const DetailView = (id) => {

        setID(id)
        setCreateDocument(true)
    }




    useEffect(() => {
        fetchTable();
    }, [searchKeyword, pageNumber, refresh])






    const fetchTable = (retryCount = 0) => {
        const maxRetries = 3;
        if (!TableUpdate) {
            setLoading(true)
        }
        Document.get({
            keyword: searchKeyword,
            page: Number(pageNumber) + 1,
            limit: 20

        }).then(response => {
            setList(response.data.data);
            setLoading(false);
        }).catch(() => {
            if (retryCount < maxRetries) {
                fetchTable(retryCount + 1);
            } else {
                setLoading(false);
                toast.error("An error occurred. Please try again later or contact the administrator.", { autoClose: 6000 })
            }
        }).finally(() => {
            if (TableUpdate) {
                setTableUpdate(false)
            }

        });
    }


    return (
        <>
            {showCreateDocument ? (

                <Create
                    setCreateDocument={setCreateDocument}
                    editId={editId}
                    setEditId={setEditId}
                    onUpdate={handleUpdate}
                    ID={ID}
                    setID={setID}
                    UpdateID={UpdateID}
                    setUpdateID={setUpdateID} />

            ) : (

                <>
                    {showInfoPopup && <OverView ID={ID} setshowInfoPopup={setshowInfoPopup} setID={setID} />}
                    <Card sx={{ p: 1, display: "flex", justifyContent: "space-between", mb: 1, mt: 1, boxShadow: 0 }} className="page_header">
                        <PageHeader title={"Documents"} total={list && !loading && list.total} />
                        <Button
                            className='create-btn'
                            variant="contained"
                            onClick={() => {
                                setCreateDocument(true)
                                setPageNumber(undefined)
                            }}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <AddIcon sx={{ marginRight: '2px' }} />  {/* Add a margin to the icon if needed */}
                            Add Document

                        </Button>

                    </Card >
                    {showDeletePopup && <Delete
                        ID={ID}
                        setID={setID}
                        setDeletePopup={setDeletePopup}
                        Callfunc={() => fetchTable()}
                        url="/hr/documents/delete?id="
                        title="Document" />}

                    {!TableUpdate && loading ? (
                        <Fragment>
                            <Box id="ui-ball-loader" >
                                <WaveformSpinner />
                            </Box>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Card sx={{ m: 2 }} variant="outlined">
                                <Grid width={'30%'} pt={1}>
                                    <SearchField
                                        clearSerachInput={clearSerachInput}
                                        serachEntry={serachEntry}
                                        handleSearchEntry={handleSearchEntry}
                                    />

                                </Grid>

                                {list?.data?.length > 0 ? (
                                    <Fragment>
                                        {typeof list.data === "object" &&
                                            <DataGrid
                                                paginationMode="server"
                                                rows={list?.data}
                                                columns={columns}
                                                page={pageNumber}
                                                pageSize={10}
                                                rowHeight={20}
                                                rowsPerPageOptions={[10]}
                                                rowCount={list.total}
                                                onPageChange={handlePageChange}
                                                autoHeight
                                                density={"comfortable"}
                                                getRowHeight={() => 35}
                                            />
                                        }
                                    </Fragment>
                                ) : (
                                    <Box id='data-not-found-msg'>
                                        <Typography alignSelf={'center'}>Document not found.</Typography>
                                    </Box>
                                )}
                            </Card>
                        </Fragment>
                    )}


                </>
            )
            }

        </>

    )
};

export default Documents;
