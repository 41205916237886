import React from 'react'

function Appraisals() {
    return (
        <div>
            <h1>Layout will be added soon</h1>
        </div>
    )
}

export default Appraisals 
