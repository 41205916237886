import { IconButton, Menu, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
export const MoreActionButton = ({ params,
    setEditId,
    setID,
    setDeletePopup,
    setOpen,
    OverView,
    setshowInfoPopup,
    callEditFunc
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);

    };


    const handleEdit = (id) => {

        {
            setEditId &&
                setEditId(parseInt(id));
        }
        callEditFunc(id)

        handleClose()
    }



    const InfoHandler = (id) => {
        setID(id)
        setshowInfoPopup(true)
        handleClose()
    }

    const handleDelete = (id) => {
        setID(id)
        setDeletePopup(true)
        handleClose();
    };

    return (
        <>
            <IconButton size="small" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {OverView &&
                    <MenuItem onClick={() => InfoHandler(params.id)}>
                        <InfoIcon sx={{ fontSize: 14, mr: 1., }} />
                        Info
                    </MenuItem>}

                <MenuItem onClick={() => handleEdit(params.id)}>
                    <EditIcon sx={{ fontSize: 14, mr: 1., color: 'blue' }} />
                    Edit
                </MenuItem>
                <MenuItem onClick={() => handleDelete(params.id)}>
                    <DeleteIcon sx={{ fontSize: 14, mr: 1, color: "red" }} />
                    Delete
                </MenuItem>

            </Menu >
        </>
    );
};
