import React, { useState } from 'react';
// import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, Typography } from "@mui/material";
import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, Slide, Typography } from "@mui/material";
import ReactSelector from 'react-select';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Organisations } from '../../../../api/Endpoints/Organisation';
import SelectX from '../../../form/SelectX'
import TextInput from '../../../form/TextInput'
import DateInput from '../../../form/DateInput'
import { Departments } from '../../../../api/Endpoints/Departments';
import { Employee } from '../../../../api/Endpoints/Employee';
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { ErrorMessage } from "@hookform/error-message";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from 'react';
import moment from 'moment';
import '../../../../Custom.css'
import { Documentcategory } from '../../../../api/Endpoints/Documentcategory';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const scheme = yup.object().shape({
    name: yup.string().required(),
    remarks: yup.string(),

});


const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)


    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            props.setEditId(undefined)
        }, 100)
    };


    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });



    // const fetchDepartments = (e) => {
    //     return Departments.get({ keyword: e }).then(response => {
    //         if (typeof response.data.data.data !== "undefined") {
    //             return response.data.data.data;
    //         } else {

    //             return []
    //         }
    //     })
    // }


    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true });

        let dataToSubmit = {
            id: props?.editId,
            users_id: props?.editId,
            name: data.name,
            remarks: data.remarks,


        };

        console.log(dataToSubmit)

        let action;
        if (props.editId > 0) {

            console.log("this datatosubmit", dataToSubmit);
            action = Documentcategory.update(dataToSubmit);

        } else {
            console.log("this datatosubmit", dataToSubmit);
            action = Documentcategory.add(dataToSubmit);

        }

        action.then(response => {
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            if (response.data.errors) {
                let err = response.data.errors
                Object.keys(err).forEach((key) => {
                    toast.error(err[key].name);
                });

                return
            }

            toast.success(response.data.message)

            props.onUpdate();
            setOpen(false)
        }).catch(errors => {
            toast.error("Internal server error");
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            setOpen(false)
        })
    }



    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchDetails()
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);
        }

    }, [props.editId])



    const fetchDetails = async () => {
        setLoading(true)
        let DocumentCategoryDetails = await Documentcategory.getDetails({ id: props.editId });
        if (DocumentCategoryDetails.data.status === "success") {
            let data = DocumentCategoryDetails.data.data;
            setValue('name', data.name);
            console.log(data.name);
            setValue('remarks', data.remarks);
            setLoading(false)
        }

    }



    return (
        <div>
            <Button
                className='create-btn'
                variant="contained"
                onClick={handleClickOpen}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <AddIcon sx={{ marginRight: '2px' }} />  
                Add Document Category

            </Button>
            <Dialog
                open={open}
                PaperProps={{ sx: { width: "40%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Category" : "Create Category"}</DialogTitle>


                {loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <CircularProgress className='ld-circle' />
                    </Box >
                ) : (
                    <>
                        <DialogContent sx={{ p: 2, mt: 3 }}>
                            <Grid container >

                                <Grid item xs={12} sm={12}>


                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Grid container>
                                            <Grid sx={{ p: 1 }} item xs={12}>
                                                <TextInput control={control} name="name"
                                                    label="Name"
                                                    value={watch('name')} />
                                            </Grid>

                                            <Grid sx={{ p: 1 }} item xs={12}  >
                                                <TextInput control={control} name="remarks"
                                                    label="Remarks"
                                                    value={watch('remarks')}
                                                    isMultiline
                                                    row={5}
                                                />
                                            </Grid>
                                            <Grid sx={{ p: 1 }} item xs={12}>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="multipleErrorInput"
                                                    render={({ messages }) =>
                                                        messages &&
                                                        Object.entries(messages).map(([type, message]) => (
                                                            <p key={type}>{message}</p>
                                                        ))
                                                    }
                                                />

                                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                                    variant="outlined" color='success' type="submit">{formButtonStatus.label}</LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </form>






                                </Grid>
                                <Grid item xs={4}>

                                </Grid>


                            </Grid>



                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant='outlined' color='error' >Close</Button>
                        </DialogActions>

                    </>
                )}
            </Dialog>
        </div >

    );
};

export default Create;