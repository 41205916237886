import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import "../../../../../Custom.css"
import {
    Backdrop,
    Button,
    Card,
    CircularProgress,
    Divider,
    List,
    Box,
    Modal,
    IconButton,
    ListItemIcon,
    ListItem,
    ListItemText,
    TextField,
    Typography
} from "@mui/material";
import { EmployeeDocuments } from '../../../../../api/Endpoints/EmployeeDocument';
import { useStateContext } from '../../../../../contexts/SignIn/UsersID/usersID';
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import DocumentView from '../../../../../components/Popups/DocumentView';
import TextInput from '../../../../form/TextInput';
import { useForm } from 'react-hook-form';
import Delete from '../../../../../components/Popups/Delete';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '80vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};



function FileUploader() {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({});

    const columns = [
        {
            field: 'title',
            headerName: 'Title',
            width: 300,
            renderCell: (params) => (
                <Typography sx={{ color: 'rgb(0,158,102)', fontWeight: 300 }}>
                    {params.value}
                </Typography>
            ),
        },
        {
            field: 'document',
            headerName: 'Document',
            width: 450,
            renderCell: (params) => (

                <>
                    <DocumentView documentURL={params.value} />
                </>
            ),
        },

        {
            field: 'edit',
            headerName: 'Edit',
            width: 80,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => (
                <Button size='small' onClick={() => handleEdit(params.row.id)}>
                    <EditIcon style={{ color: 'gray', height: 19 }} />
                </Button>
            ),
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 90,
            renderCell: (params) => (
                <Button size='small' onClick={() => handleDelete(params.row.id)}>
                    <DeleteIcon style={{ color: 'red', height: 19 }} />
                </Button>
            ),
        },
    ];

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [textValue, setTextValue] = useState('');
    const [editID, setEditID] = useState(0)
    const [open, setOpen] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const { userId } = useStateContext();
    const [ID, setID] = useState(0)
    const [showDeletePopup, setDeletePopup] = useState(false)

    useEffect(() => {
        if (editID > 0) {
            fetchDocuments()
            setOpen(true)
        }

    }, [editID])
    useEffect(() => {
        fetchDocuments()
    }, [])

    const fetchDocuments = async () => {
        setLoading(true)
        let response = await EmployeeDocuments.get({ users_id: userId })
        setList(response.data.data)
        setLoading(false)
    }


    const handleEdit = async (id) => {
        setEditID(id)
        setOpen(true)
        let EmployeeDocumentDetails = await EmployeeDocuments.getDetails({ id: id });
        if (EmployeeDocumentDetails.data.status === "success") {
            let data = EmployeeDocumentDetails.data.data;
            setValue('title', data.title);
            setSelectedFiles(data?.document)

        }

    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditID(0)
        setSelectedFiles(null)
        reset()
    };

    const handleFileChange = (event) => {
        setSelectedFiles(event.target.files[0]);
    };
    const handleDelete = (id) => {
        setID(id)
        setDeletePopup(true)
    }


    const handleRemoveFile = () => {
        setSelectedFiles(null);
    };
    const onSubmit = async (data) => {

        const fd = new FormData()

        fd.append('title', data.title)
        fd.append('file', selectedFiles)


        let action;

        if (editID > 0) {
            fd.append('id', editID)
            action = await EmployeeDocuments.update(fd)
        } else {
            fd.append('users_id', userId)
            action = await EmployeeDocuments.add(fd)
        }

        if (action.data.message === 'Document has been successfully updated.') {
            fetchDocuments()
            handleClose();
            toast.success('Document has been successfully updated', { autoClose: 1000 })
        }

    };


    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    return (

        <div>
            {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                    <CircularProgress className='ld-circle' size={33} />
                    <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                        Loading...
                    </Typography>
                </Box>
            ) : (
                (
                    <>

                        {showDeletePopup && <Delete
                            ID={ID}
                            setID={setID}
                            setDeletePopup={setDeletePopup}
                            Callfunc={() => fetchDocuments()}
                            url="/employees/documents/delete?id="
                            title="Document" />}
                        <div className='flex' style={{ width: '100', display: 'flex', justifyContent: "end", marginRight: 10 }}>


                            < Button variant="contained"
                                className='create-btn'
                                sx={{ width: "180px", }}
                                onClick={handleOpen}  >Upload Document</Button >
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ textAlign: "center", pb: 5 }}>
                                        Upload Document
                                    </Typography>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <input
                                            type="file"
                                            id="file-upload"
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />

                                        <TextInput
                                            control={control}
                                            name='title'
                                            label='Document Title'
                                            row={2}
                                            isMultiline
                                            value={watch('title')}
                                        />

                                        <label label htmlFor="file-upload">
                                            <Button
                                                variant="contained"
                                                startIcon={<CloudUploadIcon />}
                                                component="span"
                                                sx={{
                                                    mt: 2,

                                                }}
                                            >
                                                Choose File
                                            </Button>

                                        </label>
                                        {selectedFiles != null && (
                                            <List sx={{ mt: 2, maxHeight: 200, overflowY: 'auto' }}>

                                                <ListItem>
                                                    <ListItemIcon>
                                                        <CloudUploadIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={selectedFiles.name || selectedFiles} />
                                                    {
                                                        selectedFiles.name && (
                                                            <IconButton
                                                                startIcon={<DeleteIcon />}
                                                                edge="end"
                                                                onClick={() => handleRemoveFile(selectedFiles)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        )
                                                    }

                                                </ListItem>


                                            </List>
                                        )}
                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                            <Button
                                                variant="outlined"
                                                color='error'
                                                type="submit"
                                                sx={{
                                                    mt: 2, mr: 2,


                                                }}
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </Button>

                                            <Button variant="outlined"
                                                color='success'
                                                type="submit" sx={{ mt: 2 }}>
                                                Submit
                                            </Button>

                                        </div>
                                    </form>

                                </Box>
                            </Modal>

                        </div >
                        <Card sx={{ mt: 5 }} variant="outlined">
                            {typeof list.data === "object" &&
                                <DataGrid
                                    paginationMode="server"
                                    rows={list?.data}
                                    columns={columns}
                                    page={pageNumber}
                                    pageSize={10}
                                    rowHeight={20}
                                    rowsPerPageOptions={[10]}
                                    rowCount={list.total}
                                    onPageChange={handlePageChange}
                                    autoHeight
                                    density={"comfortable"}
                                    getRowHeight={() => 35}

                                />
                            }
                        </Card>

                    </>
                )

            )
            }
        </div >

    );
}

export default FileUploader;
