import React, { useState } from 'react';
// import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, Typography } from "@mui/material";
import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, Slide, Typography } from "@mui/material";
import ReactSelector from 'react-select';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Organisations } from '../../../../api/Endpoints/Organisation';
import SelectX from '../../../form/SelectX'
import TextInput from '../../../form/TextInput'
import DateInput from '../../../form/DateInput'
import { Departments } from '../../../../api/Endpoints/Departments';
import { Employee } from '../../../../api/Endpoints/Employee';
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { ErrorMessage } from "@hookform/error-message";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from 'react';
import moment from 'moment';
import { Holidaycalerndar } from '../../../../api/Endpoints/Holidaycalerndar';
import { Checkbox, FormControlLabel } from '@mui/material';
import { RatingAPI } from '../../../../api/Endpoints/Rating';




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});






const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({});


    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedRadio, setSelectedRadio] = useState({
        ratable_by_client: false,
        ratable_by_manager: false,
        ratable_by_self: false,
    });



    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            props.setEditId(undefined)
        }, 100)
    };


    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });





    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        const dataToSubmit = {
            id: props?.editId,
            name: data.title,
            ratable_by_client: selectedRadio?.ratable_by_client ? 1 : 0,
            ratable_by_manager: selectedRadio?.ratable_by_manager ? 1 : 0,
            ratable_by_self: selectedRadio?.ratable_by_self ? 1 : 0
        };


        let action;

        if (props.editId > 0) {


            action = RatingAPI.updateRatingValues(dataToSubmit);

        } else {

            action = RatingAPI.addRatingValues(dataToSubmit);

        }

        action.then(response => {
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            if (response.data.errors) {
                let err = response.data.errors
                Object.keys(err).forEach((key) => {
                    toast.error(err[key].name);
                });

                return
            }
            setSelectedRadio(false)
            toast.success(response.data.message)

            props.onUpdate();
            setOpen(false)
        }).catch(errors => {
            toast.error("Internal server error");
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            setOpen(false)
        })
    }



    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchDetails()
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);
        }

    }, [props.editId])

    // const fetchDetails =()=>{
    //     Documentcategory.getDetails({ id: props.editId }).then((response)=>{
    //         console.log(response);
    //         if(response.data.status === "success"){
    //             let data = response.data.data
    //             setValue('name', data.name);
    //             setValue('remarks', data.remarks);
    //         }
    //     }).catch(errors=>{
    //         console.log(errors);
    //     })
    // }


    const fetchDetails = async () => {
        setLoading(true)
        let RatingValues = await RatingAPI.getRatingValuesDetails({ id: props.editId });
        if (RatingValues.data.status === "success") {
            let data = RatingValues.data.data;
            setValue('title', data.name);
            setSelectedRadio({
                ratable_by_client: data.ratable_by_client,
                ratable_by_manager: data.ratable_by_manager,
                ratable_by_self: data.ratable_by_self

            })
            setLoading(false)
        }

    }






    return (
        <div>
            <Button
                className="create-btn"
                variant="contained"
                onClick={handleClickOpen}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <AddIcon sx={{ marginRight: "2px" }} />
                Add Rating Paramter
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "40%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Rating Parameter" : "Create Rating Parameter"}</DialogTitle>


                {loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <CircularProgress />
                    </Box >
                ) : (
                    <>
                        <DialogContent>
                            <Grid mt={5} >

                                <Grid item xs={12} sm={12}>


                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Grid >
                                            <Grid sx={{ p: 1 }} item xs={12} sm={12}>
                                                <TextInput control={control} name="title"
                                                    label="Title"
                                                    value={watch('title')} />
                                            </Grid>
                                            <Grid sx={{ p: 1 }} item xs={12} sm={12}>
                                                <TextInput control={control} name="description"
                                                    label="Description"
                                                    isMultiline
                                                    row={5}
                                                    value={watch('description')} />
                                            </Grid>

                                            {/* Radio buttons */}
                                            <Grid item xs={12} mt={3}>
                                                <FormControl component="fieldset">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedRadio.ratable_by_self}
                                                                onChange={(event) => setSelectedRadio({ ...selectedRadio, ratable_by_self: event.target.checked })}
                                                            />
                                                        }
                                                        label="Ratable by Self"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl component="fieldset">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedRadio.ratable_by_client}
                                                                onChange={(event) => setSelectedRadio({ ...selectedRadio, ratable_by_client: event.target.checked })}
                                                            />
                                                        }
                                                        label="Ratable by Client"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl component="fieldset">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedRadio.ratable_by_manager}
                                                                onChange={(event) => setSelectedRadio({ ...selectedRadio, ratable_by_manager: event.target.checked })}
                                                            />
                                                        }
                                                        label="Ratable by Manager"
                                                    />
                                                </FormControl>
                                            </Grid>


                                            <Grid sx={{ p: 1 }} item xs={12} mt={5}>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="multipleErrorInput"
                                                    render={({ messages }) =>
                                                        messages &&
                                                        Object.entries(messages).map(([type, message]) => (
                                                            <p key={type}>{message}</p>
                                                        ))
                                                    }
                                                />

                                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                                    variant="outlined" color='success' type="submit">{formButtonStatus.label}</LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </form>






                                </Grid>


                            </Grid>



                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant='outlined' color='error'>Close</Button>
                        </DialogActions>

                    </>
                )
                }
            </Dialog >
        </div >

    );
};

export default Create;