import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Button, Grid, IconButton } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

// CSS


const PdfPopup = ({ documentURL, fileName, EditID }) => {

    //Replace the BASE URl end
    const baseUrl = process.env.REACT_APP_API_PATH.replace('/api', '');

    return (
        <>
            {documentURL || EditID ? (
                <>
                    <a
                        href={`${baseUrl}${documentURL}`}
                        title={fileName}
                        target='_blank'
                    >
                        <IconButton startIcon={<RemoveRedEyeIcon />} edge="end">
                            <RemoveRedEyeIcon />
                        </IconButton>
                    </a>
                </>
            ) : (
                <span>Document URL is not available</span>
            )
            }
        </>
    );

};

export default PdfPopup;
