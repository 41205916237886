import { Backdrop, Button, Card, CircularProgress, TextField, Typography, Grid, InputAdornment, IconButton, Box } from "@mui/material";
import PageHeader from "../../common/page-header";
import { Fragment, useState } from "react";
import { Employee } from "../../../../api/Endpoints/Employee";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicTabs from "./TabsMenu";
import "../../../../Custom.css"
import EditIcon from '@mui/icons-material/Edit';
import Create from "./CreateEmployeeTabs/create";
import ReactSelector from 'react-select';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Waveform } from '@uiball/loaders'
import { toast } from "react-toastify";
import { fetchEmployeeRoles } from "../../../CommonFunction/Index";
import SelectX from "../../../form/SelectX";
import SearchField from '../../../../components/searchEntry/Index'
import AccessPopup from "../../../../components/Popups/AccessPopup";
import moment from "moment";
const scheme = yup.object().shape({

})

const Employees = () => {

    const columns = [

        {
            field: 'name', headerName: 'Name', width: 250, renderCell: (params) => (
                <>

                    <Typography sx={{ color: params.row.employee_status === "Employee" ? 'red' : 'black', fontWeight: 500, cursor: 'pointer' }} onClick={() => { ViewTabs(params.row.employee_id) }}>{params.value}</Typography>
                </>
            ),

        },

        { field: 'email', headerName: 'Email', width: 200, renderCell: (params) => <>{params.value}</> },


        { field: 'role', headerName: 'Role', width: 230 },


        { field: 'manager_name', headerName: 'Manager', width: 200, },

        {
            field: 'edit',
            headerName: 'Edit',

            width: 100,
            renderCell: (params) => (
                <>
                    <Button
                        size='small'
                        onClick={() => handleEdit(params.row.employee_id)}
                    >
                        <EditIcon style={{ color: 'gray', height: 18 }} />
                    </Button>
                </>
            )
        },
        {
            field: 'Last_Sign_In_Date', headerName: 'Last Sign In Date', width: 200, renderCell: (params) => <>                                    {params.row.logged_out_time ? moment(params.row.logged_out_time).format('hh:mm A') : "-"}
            </>
        },

    ]

    const { watch, setValue, control } = useForm({});
    const [refresh, setRefresh] = useState(false)
    const [editId, setEditId] = useState()
    const [CreateEmployeeTab, setCreateEmployeeTab] = useState(false)
    const [Tabs, setTabs] = useState(false)
    const [pageNumber, setPageNumber] = useState(0);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ID, setID] = useState(0)
    const [searchKeyword, setSearchKeyword] = useState();
    const [TableUpdate, setTableUpdate] = useState(false)
    const [serachEntry, setSearchEntry] = useState('')


    const ForTableUpdate = () => {
        setTableUpdate(true)
        fetchTable()
    }

    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const handleEdit = (id) => {

        setEditId(parseInt(id));
    }

    const ViewTabs = (id) => {
        setID(id)
        setTabs(true)
    }


    const handlePageChange = (page) => {
        setPageNumber(page)
    }
    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
        setSearchEntry(event.target.value)
        setTableUpdate(true)
        setPageNumber(0)
    }


    const clearSerachInput = () => {
        setSearchEntry('');
        setPageNumber(0)
        setSearchKeyword()

    }



    useEffect(() => {
        fetchTable();

    },
        [
            pageNumber,
            searchKeyword,
            watch('employee_role'),
            watch('employee_type'),
            watch('employee_level')

        ])



    //fetch Data with Retry Count

    const fetchTable = (retryCount = 0) => {
        const maxRetries = 3;
        if (!TableUpdate) {
            setLoading(true);
        }
        let pageToFetch = Number(pageNumber) + 1;
        if (watch('employee_role') || searchKeyword) {
            pageToFetch = -3;
        }
        Employee.get({
            keyword: searchKeyword,
            page: pageToFetch,
            employee_role: watch('employee_role')?.id,
            employee_level: watch('employee_level'),
            employee_type: watch('employee_type'),
        })
            .then(response => {
                setList(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                if (retryCount < maxRetries) {
                    console.error(error);
                    fetchTable(retryCount + 1);
                } else {
                    setLoading(false);
                    toast.error("An error occurred. Please try again later or contact the administrator.", { autoClose: 6000 });
                }
            }).finally(() => {
                if (TableUpdate) {
                    setTableUpdate(false);
                }
            });
    };



    //Dropdown Values

    const EmployeeType = [
        { value: "Consultant", label: 'Consultant' },
        { value: "Employee", label: 'Employee' },
        { value: "Ex-employee", label: 'Ex-employee' },

    ]

    const EmployeeLevel = [
        { value: "Trainee", label: 'Trainee' },
        { value: "Intern", label: 'Intern' },
        { value: "Executive", label: 'Executive' },
        { value: "Manager", label: 'Manager' },
        { value: "Senior Executive", label: 'Senior Executive' },
        { value: "Director", label: 'Director' }

    ];

    return (

        <Fragment>
            {Tabs ? (

                <BasicTabs setTabs={setTabs} ID={ID} fetchTable={() => ForTableUpdate()} />

            ) : (
                <Fragment>


                    <Card sx={{
                        p: 1, display: "flex", justifyContent: "space-between", mb: 1, mt: 1, boxShadow: 0, backgroundColor: 'transparent'
                    }} >

                        <PageHeader title={"Employees"} total={list.total} />

                        <Create
                            key={refresh}
                            // onNew={handleCreate}

                            onUpdate={ForTableUpdate}
                            editId={editId}
                            onNew={handleCreate}
                            setEditId={setEditId}
                            setCreateEmployeeTabs={setCreateEmployeeTab}
                            CreateEmployeeTabs={CreateEmployeeTab} />

                    </Card >
                    {/* if table update is false and loading is true show the loaidng screen */}
                    {!TableUpdate && loading ? (
                        <Fragment>
                            <Box id="ui-ball-loader" >
                                <Waveform
                                    size={50}
                                    lineWeight={3.5}
                                    speed={1}
                                    color="rgb(63,197,149"
                                />
                            </Box>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Card sx={{ m: 2 }} variant="outlined">
                                <Grid display={"flex"} justifyContent={'space-between'} pt={1} xs={12} sm={12}>
                                    <SearchField
                                        clearSerachInput={clearSerachInput}
                                        serachEntry={serachEntry}
                                        handleSearchEntry={handleSearchEntry}
                                    />
                                    <Grid display={'flex'} >
                                        <Grid mr={1} sx={{ width: 200 }}>
                                            <ReactSelector

                                                placeholder="Employee Type"
                                                options={EmployeeType}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                name='employee_type'
                                                isClearable
                                                value={
                                                    EmployeeType.find(options =>
                                                        options.value === watch('employee_type')
                                                    )
                                                }
                                                onChange={(selectedValue) => setValue("employee_type", selectedValue?.value) || setPageNumber(0)}
                                            />
                                        </Grid>
                                        <Grid mr={1} sx={{ width: 200 }}>
                                            <SelectX
                                                placeholder={"Employee Role"}
                                                options={fetchEmployeeRoles}
                                                control={control}
                                                name={'employee_role'}
                                            />
                                        </Grid>
                                        <Grid sx={{ width: 200 }}>
                                            <ReactSelector
                                                placeholder="Employee Level"
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                name='employee_level'
                                                isClearable
                                                options={EmployeeLevel}
                                                value={
                                                    EmployeeLevel.find(options =>
                                                        options.value === watch('employee_level')
                                                    )
                                                }
                                                onChange={(selectedValue) => setValue("employee_level", selectedValue?.value) || setPageNumber(0)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {list?.data?.length > 0 ? (
                                    <Fragment>
                                        {
                                            typeof list.data === "object" &&
                                            <DataGrid
                                                paginationMode="server"
                                                rows={list?.data}
                                                columns={columns}
                                                page={pageNumber}
                                                pageSize={10}
                                                rowsPerPageOptions={[10]}
                                                rowCount={list.total}
                                                onPageChange={handlePageChange}
                                                autoHeight
                                                density={"comfortable"}
                                                getRowHeight={() => 35}

                                            />
                                        }
                                    </Fragment>
                                ) : (
                                    <Box id='data-not-found-msg'>
                                        <Typography alignSelf={'center'}>Employee not found.</Typography>
                                    </Box>
                                )}

                            </Card>
                        </Fragment>
                    )}
                </Fragment>
            )
            }

        </Fragment >

    )
};

export default Employees;