

import React, { useState } from 'react';
import { Box, Button, Checkbox, Grid, IconButton, Popover, Typography } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';

function AccessPopup() {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <>
            <IconButton>
                <KeyIcon onClick={handleClick} />
            </IconButton>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ width: 200 }} display={'flex'} flexDirection={'column'}>
                    <Grid display={'flex'} alignItems={'center'}>
                        <Checkbox defaultChecked id='works' />  <label htmlFor='works'>Works Portal</label>
                    </Grid>
                    <Grid display={'flex'} alignItems={'center'}>
                        <Checkbox id='hr' /> <label htmlFor='hr'>HR</label>
                    </Grid>
                    <Grid display={'flex'} alignItems={'center'}>
                        <Checkbox id='client' /> <label htmlFor='client'>Client</label>
                    </Grid>
                    <Grid display={'flex'} alignItems={'center'}>
                        <Checkbox id='inventory' /> <label htmlFor='inventory'>Inventory</label>
                    </Grid>
                    <Grid display={'flex'} alignItems={'center'}>
                        <Checkbox id='accounts' /> <label htmlFor='accounts'>Accounts</label>
                    </Grid>
                    <Grid display={'flex'} alignItems={'center'}>
                        <Checkbox id='seo' /> <label htmlFor='seo'>SEO Portal</label>
                    </Grid>
                </Box>
               

            </Popover>
        </>
    );
}

export default AccessPopup;
