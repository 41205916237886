import React, { useState } from 'react';
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, Typography } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Document } from '../../../../api/Endpoints/Document';
import SelectX from '../../../form/SelectX'
import TextInput from '../../../form/TextInput'
import DateInput from '../../../form/DateInput'
import { Documentcategory } from "../../../../api/Endpoints/Documentcategory";
import { Employee } from '../../../../api/Endpoints/Employee';
import { LoadingButton } from "@mui/lab";
import { toast } from 'react-toastify';
import { ErrorMessage } from "@hookform/error-message";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import FileUpload from '../../../../components/FileUploader/index'
import { Box, CircularProgress } from '@material-ui/core';
import RichTextBox from '../../common/RichTextBox';
import Spinner from '../../../../components/Loader';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const scheme = yup.object().shape({

    title: yup.string().required("field is requried"),
    // email: yup.string().email(),
    // phone_number: yup.string(),
    // employee_roles_id: yup.object(),
    // address: yup.string(),
    // emergency_contacts: yup.string(),
    // joining_date: yup.date(),
    // remarks: yup.string(),
});


const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState(null);


    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = async () => {
        setSelectedFiles(null)
        props.setEditId(undefined)
        setOpen(false)
        props.setID(0)
        props.setCreateDocument(false)
        props.setUpdateID(0)
        await props.onUpdate()

    };



    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    let fetchCategories

    fetchCategories = (e) => {
        return Documentcategory.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }
    let fetchStatus;
    if (open) {
        fetchStatus = (e) => {
            return Documentcategory.get({ keyword: e }).then(response => {
                if (typeof response.data.data.data !== "undefined") {
                    return [
                        {name:'Draft'},
                        {name:'Ready for review'},
                        {name:'On Hold'},
                        {name:'Publish'}
                    ]
                } else {
                    return [];
                }
            })
        }

    }



    const fetchDetails = async () => {
        setLoading(true)
        let DocumentDetails;
        if (props.editId) {
            DocumentDetails = await Document.getDetails({ id: props.editId });
        } else {
            DocumentDetails = await Document.getDetails({ id: props.ID });
        }

        if (DocumentDetails.data.status === "success") {

            let data = DocumentDetails.data.data;
            setValue('title', data.title);
            setValue('document_category_id', data.document_category);
            if (data?.remarks !== "null") {
                setValue('remarks', data.remarks)
            }
            if (data?.content !== "undefined") {
                setValue('content', data?.content)
            } else {
                setValue('content', " ")
            }
            setSelectedFiles(data.document)
            setLoading(false)
        }

    }

    const onSubmit = (data) => {

        // if (selectedFiles == undefined) {
        //     setErr("Please select a file")
        //     return
        // }

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        const fd = new FormData();
        fd.append('id', props?.UpdateID)
        fd.append('file', selectedFiles)
        fd.append('title', data.title)
        fd.append('users_id', 2764)
        fd.append('remarks', data.remarks)
        fd.append('content', data.content)
        fd.append('document_category_id', data?.document_category_id?.id)

        let action;

        if (props.UpdateID > 0) {
            action = Document.update(fd);
        } else {
            action = Document.add(fd);

        }


        action.then((response) => {
            // Handle success

            setFormButtonStatus({ label: "Submit", loading: false, disabled: false });

            if (response.data.errors) {
                toast.error("The email or phone number has already been taken");
                return;
            }

            toast.success(response.data.message, { autoClose: 2000 });

            if (response.data.message == 'Document has been successfully updated.') {
                handleClose()
                return
            }
            reset()
            setValue('document_category_id', null);

            setSelectedFiles(null)
        })
            .catch((errors) => {
                // Handle error
                toast.error("Internal server error");
                setFormButtonStatus({ label: "Submit", loading: false, disabled: false });
                setOpen(false);
            });
    };

    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchDetails()
            setOpen(true);
        } else if (parseInt(props.ID) > 0) {
            fetchDetails()
            setOpen(true);
        } else {
            console.log("workign");
            setOpen(true)
        }

    }, [])

    let isDecriptionExist = watch('content')

    return (
        <>

            <CardContent sx={{ backgroundColor: '#ffffff' }}>

                {loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <Spinner />
                    </Box >
                ) : (
                    <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container sx={{ mt: 2 }}>

                                <Grid item xs={12} sm={12}>



                                    <Grid >
                                        <Grid container spacing={1} mt={2}>
                                            <Grid item xs={6} sm={6}>
                                                <TextInput control={control} name="title"
                                                    label="Document Name"
                                                    value={watch('title')}
                                                    isDisable={props.ID ? true : false}
                                                />
                                            </Grid>

                                            <Grid item xs={3} sm={3}>
                                                <SelectX
                                                    defaultOptions
                                                    label={props.ID ? "Category" : "Choose a Category"}
                                                    options={fetchCategories}
                                                    control={control}
                                                    error={errors.message}
                                                    name={'document_category_id'}
                                                    defaultValue={watch('document_category_id')}
                                                    type={props.ID ? "read-only" : false}
                                                />
                                            </Grid>
                                            <Grid item xs={3} sm={3}>
                                                <SelectX
                                                    defaultOptions
                                                    label={"Status"}
                                                    options={fetchStatus}
                                                    control={control}
                                                    error={errors.message}
                                                    name={'status'}
                                                    defaultValue={watch('status')}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid sx={{ p: 1 }} item xs={12}>
                                            <FileUpload
                                                selectedFiles={selectedFiles}
                                                setSelectedFiles={setSelectedFiles}
                                                err={err}
                                                ID={props.ID}
                                                EditID={props.editId}
                                                setEditID={props.setEditId}
                                                CallFunc={fetchDetails} />
                                        </Grid>
                                        {props.ID && !isDecriptionExist ? (
                                            null
                                        ) : (
                                            <Grid sx={{ p: 1 }} item xs={12}  >
                                                <RichTextBox val={watch('content')}
                                                    isReadOnly={props.ID ? true : false}
                                                    onValueChange={e => setValue('content', e)} />

                                            </Grid>
                                        )}

                                        <Grid sx={{ p: 1 }} item xs={12}  >
                                            <TextInput control={control} name="remarks"
                                                label="Remarks"
                                                value={watch('remarks')}
                                                row={6}
                                                isMultiline
                                                isDisable={props.ID ? true : false} />
                                        </Grid>

                                    </Grid>



                                    <DialogActions>
                                        <Button onClick={handleClose} variant='outlined' color='error'>Cancel</Button>
                                        {props.ID ?

                                            null

                                            : (

                                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                                    variant="outlined" color='success' type="submit">{formButtonStatus.label}</LoadingButton>

                                            )
                                        }
                                    </DialogActions>

                                </Grid>
                            </Grid>
                        </form>
                    </>
                )
                }
            </CardContent >
        </>
    );
};

export default Create;
