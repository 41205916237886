import { get, post } from './../Config/config';

export const Departments = {

    get: (data) => get('/departments', { params: data }),
    add: (data) => post('/departments/store', data),
    getDetails: (data) => get('/departments/view?id=', { params: data }),
    delete: (data) => get(`/departments/delete?id=${data} `),
    update: (data) => post(`/departments/update`, data)
}
