import { Grid, TextField, Button, Typography, Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NotesAPI } from '../../../../../api/Endpoints/Notes';
import { toast } from 'react-toastify';
import NoteCard from '../../../../../components/card/Notes';
import { useStateContext } from '../../../../../contexts/SignIn/UsersID/usersID';
import { LoadingButton } from '@mui/lab';


function Notes() {
    const [list, setList] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [formData, setFormData] = useState(null)
    const [loading, setLoading] = useState(false);
    const { userId } = useStateContext();
    const [err, setErr] = useState(false)
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    useEffect(() => {
        fetchNotes();
    }, []);

    const fetchNotes = () => {
        setLoading(true);
        NotesAPI.get({ page: Number(pageNumber) + 1, users_id: userId }).then(response => {
            setList(response.data.data.data);
            setLoading(false);
        });
    };


    const onSubmit = async () => {
        if (formData == null) {
            setErr(true);
            return;
        }


        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        let dataToSubmit = {
            users_id: userId,
            note: formData
        }
        let response = await NotesAPI.add(dataToSubmit)
        if (response.data.message === 'New note has been successfully saved.') {
            toast.success('New note has been successfully saved', { autoClose: 1000 });
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            fetchNotes()
        } else {
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        }
        setFormData('')
        setErr(false);
    }
    return (
        <div>


            <Grid alignItems="center" display={'flex'} sx={{ p: 1, justifyContent: 'center' }} item xs={8}>
                <TextField
                    sx={{ width: '60%' }}
                    label="Add Note"
                    size="small"
                    rows={3}
                    multiline
                    value={formData}
                    onChange={(e) => {
                        setFormData(e.target.value);
                    }}
                />

                <LoadingButton
                    sx={{ ml: 2 }}
                    onClick={onSubmit}
                    loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                    variant="contained" color='success' type="submit">{formButtonStatus.label}</LoadingButton>
            </Grid>
            {err && (
                <Typography color="error" variant="body2" sx={{ marginLeft: '16%', marginTop: 1 }}>
                    field is requried
                </Typography>
            )}
            {loading ?
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                    <CircularProgress className='ld-circle' size={33} />
                    <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                        Loading...
                    </Typography>
                </Box> : (
                    <>
                        <Grid sx={{ mt: 3, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
                            {list?.map(note => (
                                <NoteCard key={note.id} note={note} fetchNotes={fetchNotes} />
                            ))}
                        </Grid>
                    </>
                )
            }
        </div >
    );
}

export default Notes;

